@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}
.validationform{
  font-size: 68%!important;
  padding: 0px 0px;
  color: #f1730c  !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

h1,
h2,
h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-bottom: 0px;
}

span {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.header {
  background: #ffffff;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.19);
  height: 75px;
  padding: 10px;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 1400;
  padding-right: 25px;
}

.Layout {
  padding-top: 76px;
}

.side-nav {
  width: 300px;
  background-color: #f6f7fa;
}
.side-nav1 {
  display: none;

  /* width:100px;
  background-color: #F6F7FA; */
}

.sidebarhead {
  margin-top: 50px;
}

.sidebarhead h3 {
  margin-left: 35px;
}

.page-container {
  width: 100%;
  /* width: calc(100% - 300px); */
  height: 92vh;
  overflow-y: scroll;
  background: #fbfbfb;
}
.page-container1 {
  width: 100%;
  /* width: calc(100% - 100px); */
  height: 92vh;
  overflow-y: scroll;
  background: #fbfbfb;
 
  /* transition: 0.5s; */
}

/* siderbar */
.side-menu li a {
  cursor: pointer;
  padding: 10px 32px;
  font-size: 18px;
  color: #8892a6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.side-menu li ul {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease;
}

.submenu {
  max-height: 500px !important;
}

.downArrow {
  width: 12px;
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}

.upArrow {
  transform: rotate(180deg);
}

.side-menu li a:hover,
.side-menu li a.active {
  color: #ffb171;
}

.side-menu li ul a.actives {
  color: #3f0a87;
  border-left: 2px solid orange;
}

.active {
  color: #3f0a87;
  border-left: 3px solid orange;
  padding-left: 30px !important;
}

.header {
 background: #fff;
}

.dropdown {
  position: relative;
  display: inline-block;
  /* display: flex;
  align-items: center; */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* sidebar */

/* header */

.renewbuylogo {
  height: 50px;
  margin-left: 18px;
  margin-right: 140px;
}

.renewbuylogo {

  height: 42px;
  margin-left: 23px;
  margin-right: 100px;

}
/* spiner */

.loadingSpinnerContainer {

  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #FF943D transparent #FF943D transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* spiner */
/* .renewbuylogo img {
  height: 45px;
} */
/* .profile h4 {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
}

.profile h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
} */

.mainWrapper {
  padding: 0 20px 0 20px;
}
.profiler {
  padding-left: 28px;
}
.imagelogo {
  width: 30px;
  height: 30px;
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  border-radius: 30%;
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagelogo2 {
  width: 30px;
  height: 30px;

  border-radius: 30%;
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagelogose {
  width: 30px;
  height: 30px;
  background: linear-gradient(180deg, #19e234 0%, #7aff3d 100%);
  border-radius: 30%;
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navIcon {
  display: flex;
  align-items: center;
}

.navIcon p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #5a5a5a;
}

.subnavIcon {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

.subnavlist {
  padding: 5px 32px !important;
  margin: 0px !important;
}

.menuhead {
  padding: 53px 32px 10px;
}
/* .menuhead1 {
  padding: 82px 32px 10px;
} */

/* home section css */

.overview {
  display: flex;
  justify-content: space-between;
}

.switch {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 36px;
}

.switchbutton {
  display: flex;
  
}

.switchbutton button{
  width: 77px;
  height: 36px;
  border: none;
  color: #535353;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  margin-right: 4px;
}

.switchactive {
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  border-radius: 8px;
  width: 77px;
  height: 36px;
  color: #fff !important;
}

.heart {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #45def4 0%, #61fe9a 100%);
  border-radius: 36.75px;
}

.heart img {
  width: 60%;
}

.overviewcard p {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 18px;
  color: #535353;
}

.card-radius span {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.04em;
}

.totaldownload {
  background: #5bf7ab;
  border-radius: 136.5px;
  height: 5px;
}

.totalshare {
  background: #45dff0;
  border-radius: 136.5px;
  height: 5px;
}

.card-radius {
  border-radius: 24px;
  background: url("../image/Logos/wave.png");
  background-repeat: no-repeat;
  /* background-position: right bottom; */
  background-size: cover;

  opacity: 0.9;
}

.searchInput {
  position: relative;
}

.searchInput {
  background: #ffffff;
  opacity: 0.2;
  border: 1px solid #808191;
  border-radius: 23px;
  padding: 8px 50px 8px 20px;
  width: 380px;
}

.searchInput {
  position: relative;
}

.searchInput input {
  width: 100%;
  border: none;
  outline: none;
}
  .webinarmodelbox {
    box-shadow: 24;
    width: 557px;
    height: 178px;
    max-width: 653px;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
/* #modal-desc{
  width: 50%!important;
} */
.css-1c7ag9z-JoySheet-root{
  padding: 0!important;
  border: none!important;
}
.css-pnf0rk,.css-1tb3461  {
  padding: 0!important;
  border: none!important;
}
.css-1nvyafe-JoySheet-root{
  padding: 0!important;
  border: none!important;
}
.css-17ngpsv-JoyModalClose-root:hover{
  background-color: white!important;
  color: '#808191'!important;
  border-color: '#808191'!important;
}
.css-1v5w5hh:hover ,.css-1v5w5hh:hover{
  background-color: white!important;
  color: '#808191'!important;
  border-color: '#808191'!important;
}
.searchInput::before {
  content: url("../image/icons/search.png");
  position: absolute;
  top: 27%;
  right: 8%;
}

.searchInput input::placeholder {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #666666;
}

.switchbtn {
  padding-left: 10px;
}

.peopleaddmodel{
  box-shadow: 24;
  width: 625px;
  height: 345px;
  max-width: 625px;
  background: #FFFFFF;
  border-radius: 30px;
  border: none;
  justify-content: center;
  padding: 25px 30px;
}
.webinarmodel2{
  box-shadow: 24;
  width: 557px;
  height: 258px;
  height: 258px;
  max-width: 625px;
  border: none;
  background: #FFFFFF;
  border-radius: 30px;

}

@media screen and (max-width: 572px) {
  .webinarmodelbox {
    box-shadow: 24;
    /* width: 100%; */
    height: 166px!important;
    max-width: 400px;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.webinarmodel2 {
  box-shadow: 24;
  /* width: 100%!important; */
  height: 242px!important;
  max-width: 475px;
  border: none;
  background: #FFFFFF;
  border-radius: 30px;
}
.webinarmodelboxces {
  box-shadow: 24;
  width: 100%!important;
  height: 248px!important;
  max-width: 653px;
  background: #FFFFFF;
  border-radius: 30px;
  border: none;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;

}
.peopleaddmodel{
  box-shadow: 24;
width:412px;
  height: 500px!important;
  max-width: 625px;
  background: #FFFFFF;
  border-radius: 30px;
  border: none;
  justify-content: center;
  padding: 25px 30px;
}
.modelnamelast{
  margin-top: 1rem;
}
}
@media screen and (max-width: 425px) {
  .collateraldata {
    display: block;
  }

  .searchInput {
    background: #ffffff;
    opacity: 0.2;
    border: 1px solid #808191;
    border-radius: 23px;
    padding: 7px 40px 7px 15px;
    width: 100%;
  }
  .flex-dis {
    padding-left: 250px;
    padding-bottom: 10px;
  }

  .flex-disew {
    padding-left: 200px;
    padding-bottom: 10px;
  }
  .switchbtn {
    /* margin-top: 10px; */
    display: flex;
  }
  .peopleaddmodel{
    box-shadow: 24;
  width:370px;
    height: 480px!important;
    max-width: 625px;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
    justify-content: center;
    padding: 25px 30px;
  }
  .webinarmodelbox {
    /* width: 100%; */
    height: 166px!important;
    max-width: 365px;
}
.webinarmodel2 {
  /* width: 100%!important; */
  max-width: 365px;
}
}
@media screen and (max-width: 375px) {
  .searchInput {
    background: #ffffff;
    opacity: 0.2;
    border: 1px solid #808191;
    border-radius: 23px;
    padding: 6px 40px 7px 15px;
    width: 305px;
  }
  .flex-dis {
    padding-left: 269px;
    padding-bottom: 10px;
  }
  .flex-dises {
  
    margin-top: -15px;  
  }

  .flex-disew {
    padding-left: 197px;
    padding-bottom: 10px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 16px;
  }
  .peopleaddmodel{
    box-shadow: 24;
  width:320px;
    height: 480px!important;
    max-width: 625px;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
    justify-content: center;
    padding: 25px 30px;
  }
  .webinarmodelbox {
    /* width: 100%; */
    height: 166px!important;
    max-width: 322px;
}
.webinarmodel2 {
  max-width: 325px;
}
}
@media screen and (max-width: 320px) {
  .searchInput {
    background: #ffffff;
    opacity: 0.2;
    border: 1px solid #808191;
    border-radius: 23px;
    padding: 6px 40px 7px 15px;
    width: 270px;
  }
  .flex-dis {
    padding-left: 209px;
  }
  .flex-dises {
    padding-left: 155px;
    margin-top: -15px;  
  }
  .flex-disew {
    padding-left: 170px;
    padding-bottom: 10px;
  }
  .peopleaddmodel{
    box-shadow: 24;
  width:300px;
    height: 480px!important;
    max-width: 625px;
    background: #FFFFFF;
    border-radius: 30px;
    border: none;
    justify-content: center;
    padding: 25px 30px;
  }
  .webinarmodelbox {
    /* width: 100%; */
    height: 166px!important;
    max-width: 290px;
}
.webinarmodel2 {
  max-width: 290px;
}
}
.css-a80qp5-JoySheet-root {
  position: fixed!important;
  z-index: 1300;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  --variant-borderWidth: 0px !important;
}
.css-15pvwwa {
  z-index: -1;
  position: fixed;
  inset: 0px;
  background-color: #282828 !important;
  opacity: 0.4;
  -webkit-tap-highlight-color: transparent;
  backdrop-filter: blur(0px)!important;
}
.css-lj6m45 {
  border: none !important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.commanbtn {
  border: 1px solid #ffb171;
  color: #ffb171;
  padding: 7px 13px;
  background: transparent;
  border-radius: 10px;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  height: 36px;
  width: 70px;
}
.collateraldata {
  display: flex;
  /* padding:6px 0px */
}
.renewpage {
  padding-top: 10px;
  color: #282828;
}

.collateral-details {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #666666;
}
.collateral-detailss {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  display: flex;
  color: #666666;

}

.collateral-details span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  display: block;
  color: #282828;
}
.collateral-detailss span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  display: block;
  color: #282828;
}
.approvedbtn {
  background: #00b929;
  border: 1px solid #00b929;
  border-radius: 73px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  width: 100px;
  height: 28px;
  color: #fff;
}

.deactivebtn {
  border: 1px solid #f64141;
  border-radius: 73px;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f74141;
  background: transparent;
}

.collateral {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 30px;
}
.coletrel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 30px;
}
.collrated {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 30px;
}
.collateralbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px 11px;
}
.coletrelbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0px 17px;
}
.collratedbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 3px 15px;
}
.contant {
  padding: 10px 45px;
}
.contants {
  padding: 0px 8px;
}
.contante {
  padding: 10px 20px;
  height: 100%;
}
.declinebtn {
  border: 1px solid #f64141;
  border-radius: 73px;
  background-color: transparent;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f74141;
}

.approved {
  border: 1px solid #00b929;
  border-radius: 73px;
  background-color: transparent;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #00b929;
}

.deactive {
  border: 1px solid #f64141;
  border-radius: 73px;
  background-color: transparent;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f74141;
}

.reactivebtn {
  border: 1px solid #00b929;
  border-radius: 73px;
  background-color: transparent;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #00b929;
}

.testbtn {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #666666;
}

.viewbtn {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #666666;
}

/* / Tables / */
.primary-table-theme .head {
  background-color: #dee9f9 !important;
  color: #0040a1 !important;
  border: 1px solid #dee9f9 !important;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 th {
  position: sticky;
  top: 0px;
  /* background-color: #EDF2FC!important; */
  color: #747577 !important;
  /* border: 1px solid #EDF2FC !important; */
  padding: 0.5rem;
  /* font-size: 0.8rem; */
  z-index: 9;
  text-align: center;
  border-bottom: 2px solid #edf2fc !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  background-color: #fff;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #747577;
}

.table-theme-1 tr {
  box-shadow: none;
}

.table-theme-1 tr:nth-of-type(even) {
  background-color: #edf2fc;
}

.table-theme-1 tr:hover {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important; */
  background-color: #edf2fc !important;
}

/* .table-theme-1 tr:hover{

} */

.table-theme-1 td {
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  /* background-color: #fff !important; */
  padding: 1rem 0.5rem;
  font-size: 0.8rem;
  text-align: center;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #535353;
}

/* .table-theme-1 td::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.table-theme-1 td::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  height: 1px;
  background-color: #f6f6f6;
} */

.custom-table .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee9f9;
  color: #0040a1;
  font-size: 0.8rem;
  z-index: 5;
}

.custom-table .head li {
  padding: 1rem 2rem;
}

#manage-tab .head li:nth-child(1) {
  width: 30%;
}

#manage-tab .head li:nth-child(2) {
  width: 15%;
}

#manage-tab .head li:nth-child(3) {
  width: 15%;
}

#manage-tab .head li:nth-child(4) {
  width: 15%;
}

#manage-tab .head li:nth-child(5) {
  width: 25%;
}

.custom-table .body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-table .custom-row {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 2rem;
  height: 480px;
  border-bottom: 1px solid #f6f6f6;
}

.custom-table .body .left {
  width: 30%;
  padding: 1rem;
}

.custom-table .body .right {
  width: 70%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-table .body .comment-section {
  height: 70%;
  overflow-y: auto;
}
.error{
  color: red;
  font-size: small;
}
.post-detail .header,
.post-polling .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: -9%;
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.6rem;
  color: #0040a1;
  font-size: 0.9rem;
  height: auto;
  z-index: 2;
}

.post-detail .header img,
.post-polling .header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 2px solid #fff;
}

.post-detail .header img.home,
.post-polling .header img.home {
  width: 55px;
  height: 55px;
}

.post-detail.without-text .footer {
  padding-top: 50px;
}

.post-detail .footer .title {
  padding-top: 0.5rem;
  color: #0040a1;
}

.post-detail .footer .text {
  font-size: 0.8rem;
  color: #50504f;
}

.post-polling.modal-view,
.post-detail.modal-view {
  display: flex;
  justify-content: space-between;
}

.post-polling.modal-view .content,
.post-detail.modal-view .content {
  width: 50%;
}

.post-polling.modal-view .footer,
.post-detail.modal-view .footer {
  width: 45%;
}

.post-polling.modal-view .footer h3,
.post-detail.modal-view .footer h3 {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #0040a1;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0040a1;
  font-weight: 600;
}

.custom-table .comment-lists {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #50504f;
  border-top: 0.9px solid #f6f6f6;
  border-bottom: 0.9px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

.custom-table .comment-lists li {
  display: flex;
  align-items: center;
}

.custom-table .comment-lists li:nth-child(1) {
  width: 25%;
}

.custom-table .comment-lists li:nth-child(2) {
  width: 23%;
}

.custom-table .comment-lists li:nth-child(3) {
  width: 22%;
}

.custom-table .comment-lists li:nth-child(4) {
  width: 32%;
}

.custom-table .comment-lists li:nth-child(1) img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

.editbtn {
  color: #0066af;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  letter-spacing: 0.04em;
  text-decoration-line: underline;
  cursor: pointer;
}

/* / Tables / */

/* rb store product  */

.productswitch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-service {
  border-bottom: 2px solid #f0f4f8;
  width: 96%;
  padding-left: 22px;
   padding-top: 22px;
}
.product-servicex {
  border-bottom: 2px solid #f0f4f8;
  width: 94%;
  padding-left: 22px;
   /* padding-top: 30px; */
}




/* / custom checkbox / */

.ux-btn-group {
  outline: none;
  display: flex;
  margin-bottom: 0px;

}

.ux-btn-group li {
  outline: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.ux-btn-group input {
  width: 0;
  height: 0;
  cursor: pointer;
}

.ux-btn-group input.ux-btn-group-item {
  opacity: 0;
  margin-left: 0px;

}

.ux-btn-group input.ux-btn-group-item:checked+label {
  background-image: linear-gradient(180deg, #FFB171 0%, #FF943D 100%) !important;
  outline: none;
  border: 1px solid #FF943D;
  color: #fff !important;
  cursor: pointer;
}

.ux-btn-group label {
  border: 1px solid #808191;
  border-radius: 24px;
  padding: 5px 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;
  letter-spacing: 0.04em;
  color: #808191;
  margin: 10px;
  cursor: pointer;
}

.ux-btn-group label:active {
  outline: none;
  border: 1px solid #FFB171;
  background-image: linear-gradient(180deg, #FFB171 0%, #FF943D 100%) !important;
  color: #fff !important;
}

.ux-btn-group label:focus {
  outline: none;
}

/* ---------------------- */

.ux-btn-groupes {
  outline: none;
  display: flex;
  margin-bottom: 0px;

}

.ux-btn-groupes li {
  outline: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.ux-btn-groupes input {
  width: 0;
  height: 0;
  cursor: pointer;
}

.ux-btn-groupes input.ux-btn-group-itemes {
  opacity: 0;
  margin-left: 0px;

}

.ux-btn-groupes input.ux-btn-group-itemes:checked+label {
  background: linear-gradient(180deg, #5989DE 0%, #4070C5 100%) !important;
  outline: none;
  border: 1px solid #4070C5;
  color: #fff !important;
  cursor: pointer;
}

.ux-btn-groupes label {
  border: 1px solid #808191;
  border-radius: 4px;
  padding: 3px 7px;
  width: 24px;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;
  letter-spacing: 0.04em;
  color: #808191;
  margin: 10px;
  cursor: pointer;
}

.ux-btn-groupes label:active {
  outline: none;
  border: 1px solid #FFB171;
  background-image: linear-gradient(180deg, #5989DE 0%, #4070C5 100%) !important;
  color: #fff !important;
}

.ux-btn-groupes  label:focus {
  outline: none;
}

.languagesover{
  overflow-y: scroll;
}

/* / custom checkbox / */

.merchandise-libary {
  cursor: pointer;
  margin-right: 70px;
}
.merchandise-libary h4{
  width: 179px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #535353;
}

.collaterals-lab {
  cursor: pointer;
}
.collaterals-lab h4{

  width: 110px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
  text-align: center;
  
}
.merchandise-libarye {
  cursor: pointer;
  margin-right: 70px;
}
.merchandise-libarye h4{
  width: 179px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #535353;
}

.collaterals-labe {
  cursor: pointer;
}
.collaterals-labe h4{

  width: 153px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
  
}
.productactive {
  border-bottom: 4px solid #ffb171;
  border-radius: 2px 2px 0px 0px;
}
.colletaralfilterprduct {
  overflow-x: scroll;

  /* width: 100%; */
}
.colletaralfilterprduct ul {
  border-bottom: 1px solid #f0f4f8;
  width: 100%;
}

.colletaralfilterprduct ul li {
  margin: 0px 20px;
  padding: 12px;
  cursor: pointer;
}

.colletaralfilterprduct ul li {
  cursor: pointer;
}

.activeColletaral {
  border-bottom: 2px solid #ff943d;
}

.collateral-product-type span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #282828;
}

.collateral-product-type a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.04em;
  text-decoration-line: underline;

  /* qwet */

  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.colletaraledfilterprduct ul li {
  overflow-x: hidden;
  overflow-y: scroll;
}

.editbtncol {
  border: 1px solid #5989de;
  border-radius: 73px;
  background-color: transparent;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5989de;
}

.collateral-detailsprice {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  padding-bottom: 10px;
}

/* rb store product */

/*--------------- profilepage------------ */

.profile_Avatar {
  width: 97px;
  height: 97px;
  background: #e3d5ff;
  border-radius: 50%;
}

.profile_avtar_details h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;

  color: #282828;
}

.profile_avtar_details p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: 0.04em;

  color: #808191;
  margin-top: 7px;
}

.profile_form_details p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  /* identical to box height */

  /* Primary Text - Black #282828 */

  color: #282828;
}

.profile_form_details input {
  /* width: 290px; */
  height: 48px;
  background: #ffffff;
  border: 1px solid #808191;
  border-radius: 24.2521px;
  padding: 0 20px;
  margin-top: 5px;
}

.profile_form_details input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

  color: #808191;
}

.profile_editbtn button {

  height: 48px;

  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  border-radius: 70px;
  border: none;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;

  transform: matrix(1, 0, 0, 1, 0, 0);
}

.passwordview {
  position: relative;
}

.passwordview img {
  position: absolute;
  top: 43%;
  right: 9%;

}

.viewpassword {
  cursor: pointer;
}

/* slider */

.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-prev:after {
  content: url("../image/home/next.png") !important;

  transform: rotate(180deg);
}

.swiper-button-next:after {
  content: url("../image/home/next.png") !important;
}

.barchart {
  width: 100%;
}

.chartdetails {
  width: 1360px;
  height: 300px;
}

.recharts-rectangle {
  width: 50px !important;
}

.switch-chart {
  border-bottom: 1px solid #f6f6f6;
}

.switch-image-btn {
  cursor: pointer;
}

.switch-video-btn {
  cursor: pointer;
}

.switch-active {
  border-bottom: 4px solid #ff943d;
}

.switch-chart-btn {
  border: 1px solid #f0f4f8;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.04em;
}

.switch-active-btn {
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  border-radius: 10px;
  color: #fff;
}

.excle-export {
  width: 85px;
  height: 30px;
  margin-left: 10px;
  background: none;
  border-radius: 10px;
  border: 1px solid #ff943d;
  padding: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #ff943d;
}

/* rb-store card analytics */
.analyticCardetails h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.04em;

  color: #535353;
}

.analyticCardetails p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #535353;
}

.analyticCardetails p:last-child {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #535353;
}

/* sourabh css */

/* .futural{
  box-sizing: border-box;

  
  width: 456px;
  height: 291px;
  
  
  opacity: 0.2;
  border: 1px solid #808191;
  border-radius: 30px;

} */

.customize {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #666666;
  opacity: 0.5;
}

.des-size {
  font-size: 14px;
}
.buttons-cart {
  display: flex;

}
.buttonss-cart{
  display: flex;
  padding-top:62px ;
}

.buttos-cart {
  display: flex;
  padding-top: 8px;
}
.addtoocart {
  border: 1px solid #4070c5;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4070c5;
  background: transparent;
  padding: 2px 2px 2px 2px;
}

.addtocart {
  border: 1px solid #4070c5;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #4070c5;
  background: transparent;
  padding-top: 2px;
}
.addtocartt {
  border: 1px solid #4070c5;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4070c5;
  background: transparent;
  padding-top: 2px;
}

.buynow {
  border: 1px solid #ff943d;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  font-weight: 500;
  font-size: 11px;
  margin-left: 10px;
  line-height: 15px;
  color: #ff943d;
  background: transparent;
  padding-top:2px ;
}
.buynoww {
  border: 1px solid #73e18b;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #67cc80;
  background: transparent;
}
.buynowwe {
  border: 1px solid #73e18b;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #67cc80;
  background: transparent;
}
.buynowe {
  border: 1px solid #ff943d;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ff943d;
  background: transparent;
}
.btninput {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  align-items: center;
  border: none;
  background-color: #0066af;
  color: white;
}

.btn-sx {
  box-sizing: border-box;
  width: 30px;
  height: 20px;

  border: 1px solid #dcdcdf;
  border-radius: 41px;
}
.text-cnter {
  height: 12px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #282828;
}
.cost-digit {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: -12px;
  color: #535353;
}

.img-wdth {
  max-width: 203px;
  max-height: 211px;
}
.excle-exportx {
  width: 92px;
  height: 30px;
  background: none;
  border-radius: 10px;
  border: 1px solid #ff943d;
  padding: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #ff943d;
}
.collateral-product-types {
  padding-right: 60px;
  padding-left: 57px;
}
.collateral-product-types span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #282828;
}
.collateral-product-types a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.04em;
  text-decoration: underline;

  /* qwet */

  background: linear-gradient(180deg, #5989de 0%, #4070c5 100%);
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  background-clip: text;
}
.deicate {
  box-sizing: border-box;
  width: 173px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.deicate p {
  width: 119px;
  height: 25px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;

  color: #535353;
}

.bell-img {
  width: 36px;
  height: 36px;
  position: relative;
  background: #ffffff;
  border-radius: 10px;
}

.profileimg {
  width: 108px;
  height: 36px;

  background: #ffffff;
  border-radius: 10px;
}
.profileimg p {
  position: relative;
  right: 10px;
  width: 50px;
  height: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #535353;
}
.profileimg img {
  border-radius: 12px;
  position: relative;
  right: 2px;
}

::-webkit-scrollbar {
  width: 0px;
}

.collateralbodyy {
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  padding: 3px 13px 61px;
  display: flex;
  justify-content: center;
}

.modelbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, 50%);
  box-shadow: 24;

  width: 653px;
  height: 435px;
  max-width: 653px;
  height: 435px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  border: none;
}
.modelboxse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47%, 35%);
  box-shadow: 24;
  width: 383px;
  height: 571px;
  max-width: 383px;
  height: 571px;
  border: none;
  background: #ffffff;
  border-radius: 20px;
}
.colratebox {
  padding: 28px 13px 28px 30px;
  z-index: 0;
}
.colrateing {
  padding: 20px 20px 20px 20px;
  z-index: 0;
}
.twobtn {
  padding: 0px 0px 0px 10px;
}

.colratz {
  padding: 0px 20px 15px 20px;
}
.colrateboxes {
  padding: 28px 30px 28px 30px;
}
.colrateboxes input {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #808191;
  border-radius: 24px;
  height: 48px;
  padding: 0px 25px;
}
.form-control:focus {
  box-shadow: none;
}
.form-select:focus {
  box-shadow: none;
}
.colrateboxes label {
  width: 131px;
  height: 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #282828;
}
.form-downward {
  padding-top: 20px;
}

.btn-drop {
  padding-top: 67px;
}
.cancle-btn {
  padding-left: 3px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid #808191;
  border-radius: 300px;
  background: none;
  letter-spacing: 0.04em;
  font-size: 16px;
  color: #ababab;
}

.preview-btn {
  box-sizing: border-box;
  width: 100%;
  margin-left: 11px;
  height: 40px;
  border: none;
  letter-spacing: 0.04em;
  font-size: 16px;
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  border-radius: 300px;
  color: #ffffff;
}
.placeorderbtn {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid #ff943d;
  border-radius: 300px;
  background: none;
  letter-spacing: 0.04em;
  font-size: 16px;
  color: #ff943d;
}
.downloadbtn {
  box-sizing: border-box;
  border: 1px solid #67cc80;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background: #ffffff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sharebtn {
  border: 1px solid #4070c5;
  box-sizing: border-box;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-top: 2px;
  background: #ffffff;
}
/* .figmas{
  transition: 0.2s;
  -webkit-filter: blur(2px);
  filter: blur(2px);
} */
.colrform {
  height: 48px;
  padding: 0px 25px;

  border: 1px solid #808191;
  border-radius: 24px;
}
.img-source {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
}

.img-source img {
  position: absolute;
}

.colratebox img {
  width: 273px;
  height: 375px;

  border-radius: 5px;
}

.colrateing img {
  width: 343px;
  height: 471px;
  border-radius: 5px;
}

.imgcontainer {
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 1248px) {
  .inputsearch {
    padding-right: 28px;
    padding-left: 12px;
  }
  .inputtsearch {
    padding-right: 0px;
    padding-left: 12px;
  }
  .buttons-cart {
    display: flex;
    justify-content: center;
  }
  .buttonss-cart {
    display: flex;
    justify-content: center;
  }
  .buttos-cart {
    display: flex;
    justify-content: center;
  }
  .addtocartt {
    width: 100%;
  }
  .addtocart {
    width: 100%;
  }
  .buynowe {
    width: 100%;
    margin-left: 12px;
  }
  .buynow {
    width: 100%;
    margin-left: 12px;
  }
  .buynoww {
    width: 100%;

    margin-left: 12px;
  }
  .collateral-product-types {
    padding-right: 8px;
    padding-left: 7px;
  }
}
@media screen and (max-width: 1176px) {
  .buttons-cart {
    display: flex;
    justify-content: center;
  }
  .buttonss-cart {
    display: flex;
    justify-content: center;
  }
  .buttos-cart {
    display: flex;
    justify-content: center;
  }
  .buynoww {
    margin-top: 0px;
    width: 100%;  
    margin-left: 12px;
  }
  .addtocartt {
    width: 100%;
  }
  .buynowe {
    width: 100%;
    margin-left: 12px;
   
  }
  .collateral-product-types {
    padding-right: 11px;
    padding-left: 3px;
  }
}
@media screen and (max-width: 1024px) {
  .buttons-cart {
    display: flex;
    justify-content: center;
  }
  .buttonss-cart {
    display: flex;
    justify-content: center;
  }
  .buttos-cart {
    display: flex;
    justify-content: center;
  }
  .buynow {
    width: 100%;
    margin-top: 0px;
  }

  .buynowe {
    width: 100%;
    margin-top: 0px;
    margin-left: 12px;
  }
  .addtocartt {
    margin-top: 0px;
    width: 100%;
  }

  .collateral-product-types {
    padding-right: 7px;
    padding-left: 6px;
  }
}
@media screen and (max-width: 768px) {
  .buttons-cart {
    display: flex;
  }
  .buttonss-cart {
    display: flex;
  }
  .buttos-cart {
    display: flex;
  }

  .buynowe {
    width: 100%;
    margin-top: 0px;
    margin-left: 12px;
  }
  .addtocartt {
    margin-top: 0px;
    width: 100%;
  }
  .collateral-product-types {
    padding-right: 22px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 425px) {
  .buttons-cart {
    display: flex;
    justify-content: center;
  }
  .buttonss-cart {
    display: flex;
    justify-content: center;
  }
  .buttos-cart {
    display: flex;
  }
  .buynow {
    margin-top: 0px;
    width: 100%;
  }

  .buynowe {
    width: 100%;
    margin-top: 0px;
    margin-left: 12px;
  }
  .addtocartt {
    margin-top: 0px;
    width: 100%;
  }
  .collateral-product-types {
    padding-right: 2px;
    padding-left: 3px;
  }
  .addtocart {
    width: 100%;
  }
  .buynoww {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .buttons-cart {
    display: flex;
    justify-content: center;
  }
  .buttonss-cart {
    display: flex;
    justify-content: center;
  }
  .buttos-cart {
    display: flex;
    justify-content: center;
  }
  .buynowe {
    width: 100%;
    margin-top: 0px;
    margin-left: 12px;
  }
  .addtocartt {
    margin-top: 0px;
    width: 100%;
  }
  .collateral-product-types {
    padding-right: 0px;
    padding-left: 0px;
  }
  .addtocart {
    width: 100%;
  }
  .buynoww {
    width: 100%;
  }

  .buynow {
    width: 100%;
  }
}

@media screen and (max-width: 325px) {
  .buttons-cart {
    display: flex;
    /* padding-left: 20px; */
    justify-content: center;
    margin-left: -5px;
  }
  .buttonss-cart {
    display: flex;
    /* padding-left: 20px; */
    justify-content: center;
    margin-left: -5px;
  }
  .buttos-cart {
    display: flex;
    justify-content: center;
  }
  .collateralbody {
    padding: 12px 0px;
  }
  .coletrelbody {
    padding: 12px 0px;
  }
  .collratedbody {
    padding: 10px 0px;
  }

  .addtocartt {
    width: 100%;
  }
  .addtocart {
    width: 100%;
  }
  .buynoww {
    width: 100%;
  }
  .buynowe {
    width: 100%;
    margin-left: 12px;
  }

  .buynow {
    width: 100%;
  }
}
.contein {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  letter-spacing: 0.04em;

  color: #282828;
}
.title {
  /*   */
  height: 15px;
  display: block;

  justify-content: start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #282828;
}

.customisebtn {
  box-sizing: border-box;

  border: 1px solid #ff943d;

  padding: 4px 0px;
  background: none;
  border-radius: 10px;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  letter-spacing: 0.04em;
  color: #ff943d;
}
.collateraldatas {
  display: flex;
  justify-content: center;
}
.coletreldata {
  display: flex;
  padding: 13px 0px;
  align-items: center;
  /* font-size: 16px; */
}
.collrateddata {
  display: flex;
  padding: 0px 0px;
  align-items: center;
}
.collateraldata img {
  width: auto;
  height: 14rem;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding-top: 15px;
}
.collateraldatas img {
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding-top: 10px;
}
.coletrel img {
  width: auto;
  height: 15rem;
  display: flex;
  border-radius: 5px;
}
.collrated img {
  width: auto;
  height: 14rem;
  display: flex;
  border-radius: 5px;
}
.cusm {
  margin-top: -8px;
}

@media screen and (max-width: 1440px) {
  .coletreldata {
    display: flex;
    padding: 10px 0px;
    align-items: center;
  }
  .collrateddata {
    display: flex;
    padding: 10px 0px;
    align-items: center;
  }
  .coletrelbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 4px 29px 0px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 3px 15px;
  }
  .contant {
    padding: 10px 15px;
  }
  .modelbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-31%, 50%);
    box-shadow: 24;
    width: 100%;
    height: 413px;
    background: #ffffff;
    border-radius: 30px;
    display: flex;
    border: none;
  }
  .modelboxse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, 35%);
    box-shadow: 24;
    width: 380px;
    height: 571px;
    max-width: 383px;
    height: 571px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
  }
  .colratebox {
    padding: 20px 24px 24px 24px;
    z-index: 0;
  }

  .colrateboxes {
    padding: 24px 24px 24px 4px;
  }
  .btn-drop {
    padding-top: 68px;
  }

  .colratebox img {
    width: 268px;
    height: 373px;
    border-radius: 5px;
  }
  .colrateboxes input {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #808191;
    border-radius: 24px;
    height: 46px;
    padding: 0px 25px;
  }
  .colrform {
    height: 46px;
    padding: 0px 25px;
    border: 1px solid #808191;
    border-radius: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .collateraldata {
    display: block;
  }
  .coletreldata {
    display: block;
  }
  .collrateddata {
    display: block;
  }
  .collateraldata img {
    padding-left: 0px;
  }
  /* .coletrel img{
    padding-left:0px ;
  } */
  .contant {
    padding: 10px 0px;
  }
  .contante {
    padding: 6px 0px;
  }
  .coletrelbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 30px 0px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 19px 0px;
  }

  .modelbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-31%, 50%);
    box-shadow: 24;
    width: 100%;
    height: 381px;
    background: #ffffff;
    border-radius: 30px;
    display: flex;
    border: none;
  }
  .modelboxse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-24%, 35%);
    box-shadow: 24;
    width: 100%;
    height: 571px;

    height: 551px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
  }
  .colrateing {
    padding: 14px 15px 14px 17px;
    z-index: 0;
  }
  .colratebox {
    padding: 20px 24px 24px 24px;
    z-index: 0;
  }

  .colrateboxes {
    padding: 24px 24px 24px 4px;
  }
  .btn-drop {
    padding-top: 62px;
  }
  .colratebox img {
    width: 254px;
    height: 344px;
    border-radius: 5px;
  }
  .colrateboxes input {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #808191;
    border-radius: 24px;
    height: 44px;
    padding: 0px 25px;
  }
  .colrform {
    height: 44px;
    padding: 0px 25px;
    border: 1px solid #808191;
    border-radius: 24px;
  }

  .form-downward {
    padding-top: 12px;
  }
}
@media screen and (max-width: 768px) {
  .collateraldata {
    display: block;
  }
  .coletreldata {
    display: block;
  }
  .collratedata {
    display: flex;
  }
  .collateraldata img {
    /* width: 147px; */
    /* height: 201px; */
    display: flex;
    padding-left: 0px;
    border-radius: 5px;
  }
  /* .coletreldata img{
    display: flex;
    padding-left: 0px;
    border-radius: 5px;
  }
   */

  .contante {
    padding: 7px 0px;
  }
  .coletrelbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 4px 20px 0px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 4px 17px;
  }
  .modelbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    box-shadow: 24;
    width: 653px;
    height: 435px;
    width: 100%;
    height: 383px;
    background: #ffffff;
    border-radius: 30px;
    display: flex;
    border: none;
  }
  .modelboxse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, 35%);
    box-shadow: 24;
    width: 100%;
    height: 571px;

    height: 551px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
  }
  .colrateing {
    padding: 16px 15px 16px 17px;
    z-index: 0;
  }
  .colrateboxes input {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #808191;
    border-radius: 24px;
    height: 45px;
    padding: 0px 25px;
  }
  .colrform {
    height: 45px;
    padding: 0px 25px;
    border: 1px solid #808191;
    border-radius: 24px;
  }
  .buttons-cart {
    display: flex;
    justify-content: start;
  }
  .buttonss-cart {
    display: flex;
    justify-content: start;
  }
}
@media screen and (max-width: 425px) {
  .collateraldata {
    display: block;
    justify-content: center;
  }
  .coletreldata {
    display: block;
    justify-content: center;
  }
  .collrateddata {
    display: block;
    justify-content: center;
  }
  .collateraldata img {
    padding: 4px 63px;
  }
  /* .coletreldata img{
    padding: 4px 63px;
  } */

  .contant {
    padding: 5px 3px;
  }
  .contante {
    padding: 6px 0px;
  }
  .coletrelbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 25px 0px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 17px;
  }
  .buttons-cart {
    display: flex;
    justify-content: center;
  }
  .buttonss-cart {
    display: flex;
    justify-content: center;
  }
  /* .modale{
    padding: 30px;
    border: none;
    } */
  .modelbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    box-shadow: 24;

    height: 435px;
    width: 100%;
    height: 487px;
    background: #ffffff;
    border-radius: 30px;
    display: block;
    border: none;
    overflow-y: scroll;
  }
  .modelboxse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 35%);
    box-shadow: 24;
    width: 100%;
    height: 571px;

    height: 520px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
  }
  .colrateing img {
    width: 343px;
    height: 438px;
    border-radius: 5px;
  }
  .colrateing {
    padding: 16px 15px 16px 17px;
    z-index: 0;
  }
  .colrateboxes {
    padding: 0px 22px 16px 22px;
  }
  .colratebox {
    padding: 16px 22px 16px 22px;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  .colratebox img {
    width: 260px;
    height: 330px;
    border-radius: 5px;
  }
}
@media screen and (max-width: 375px) {
  .coletrel img {
    width: 100%;
    height: 15rem;
    display: flex;
    border-radius: 5px;
  }
  /* .modale{
  padding: 25px;
  border: none;
  } */
  .modelbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    box-shadow: 24;
    width: 100%;
    height: 435px;

    height: 483px;
    background: #ffffff;
    border-radius: 30px;
    display: block;
    border: none;
    overflow-y: scroll;
  }
  .modelboxse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 35%);
    box-shadow: 24;

    height: 571px;
    width: 100%;
    height: 520px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
  }
  .colrateboxes {
    padding: 0px 22px 16px 22px;
  }
  .colratebox {
    padding: 20px 22px 16px 22px;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  .colratebox img {
    width: 260px;
    height: 330px;
    border-radius: 5px;
  }
}
@media screen and (max-width: 325px) {
  .collateraldata {
    display: block;
    justify-content: center;
  }
  .coletreldata {
    display: block;
    justify-content: center;
  }
  .collrateddata {
    display: block;
    justify-content: center;
  }
  .collateraldata img {
    padding: 4px 63px;
  }
  .coletrel img {
    width: 100%;
    height: 15rem;
    display: flex;
    border-radius: 5px;
  }
  .collrated img {
    width: 100%;
    height: 15rem;
    display: flex;
    border-radius: 5px;
  }
  .coletrelbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 4px 18px 0px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 4px 14px;
  }
  .contante {
    padding: 7px 0px;
  }

  /* .buttos-cart{
    display:block;
    padding-left: 30px;
  } */
  /* .modale{
    padding: 20px;
    border: none;
    } */
  .modelbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    box-shadow: 24;
    width: 100%;
    height: 435px;

    height: 464px;
    background: #ffffff;
    border-radius: 30px;
    display: block;
    border: none;
    overflow-y: scroll;
  }
  .colrateboxes {
    padding: 0px 22px 16px 22px;
  }
  .colratebox {
    padding: 20px 22px 16px 22px;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  .colratebox img {
    width: 260px;
    height: 330px;
    border-radius: 5px;
  }
}

.descrip {
  height: 15px;
  padding-bottom: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #666666;
}
.excel-exportable {
  width: 9px;
  height: 6px;

  /* qwet */

  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.collateraled {
  display: flex;
  transition: 0.5s;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 11px;
}

.borderbutton {
  box-sizing: border-box;

  width: 168px;
  height: 36px;

  background: #ffffff;
  border-radius: 10px;
}
.imagebutton {
  width: 77px;
  height: 30px;
  background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
  border-radius: 10px;
}
.imagebutton p {
  width: 48px;
  height: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #ffffff;
}
.borderbutton h3 {
  width: 44px;
  height: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #535353;
}
.inputsearch {
  display: flex;
  padding-right: 75px;
  padding-left: 70px;
}
.searchingbox {
  display: flex;
  padding-right: 33px;
  padding-left: 33px;
}
.inputtsearch {
  display: flex;
  padding-right:80px;
}
.inputssearch {
  display: flex;
  padding-right: 80px;
  padding-left: 37px;
}
.dropitem {
  font-size: 12px;
}
@media screen and (max-width: 1290px) {
  .inputsearch {
    padding-right: 28px;
    padding-left: 12px;
  }
  .inputtsearch {
    padding-right: 0px;
    padding-left: 12px;
  }
  .inputssearch {
    padding-right: 28px;
    padding-left: 12px;
  }
}
@media screen and (max-width: 1248px) {
  .inputsearch {
    padding-right: 21px;
    padding-left: 12px;
  }
  .inputtsearch {
    padding-right: 0px;
    padding-left: 12px;
  }
  .inputssearch {
    padding-right: 21px;
    padding-left: 12px;
  }
}
@media screen and (max-width: 1024px) {
  .inputsearch {
    padding-right: 30px;
    padding-left: 12px;
  }
  .inputtsearch {
    padding-right: 0px;
    padding-left: 12px;
  }
  .inputssearch {
    padding-right: 30px;
    padding-left: 12px;
  }
}
@media screen and (max-width: 768px) {
  .mainWrapper {
    padding: 0 28px 0 28px;
  }
  .inputsearch {
    padding-right: 24px;
    padding-left: 15px;
  }
  .inputtsearch {
    padding-right: 0px;
    padding-left: 15px;
  }
  .inputssearch {
    padding-right: 24px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 425px) {
  .borderbutton {
    box-sizing: border-box;

    width: 180px;

    height: 35px;
    margin-top: 10px;

    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 18.8734px;
  }
  .imagebutton {
    width: 120px;
    height: 25px;
    background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
    box-shadow: 0px 2.02215px 2.02215px rgba(0, 0, 0, 0.15);
    border-radius: 15.5032px;
  }

  .imagebutton p {
    width: 49px;
    height: 15px;
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    justify-content: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #ffffff;
  }
  .borderbutton h3 {
    width: 99px;
    height: 15px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    align-items: center;
    justify-content: end;
    color: #666666;
  }
  .inputsearch {
    display: flex;
    flex-direction: column-reverse;
  }
  .inputtsearch {
    display: flex;
    flex-direction: column-reverse;
  }
  .inputssearch {
    display: flex;
    flex-direction: column-reverse;
  }
  .excle-export {
    margin-top: 0px;
    width: 80px;
    display: flex;
    justify-content: center;
  }
  .hoverbtn {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mainWrapper {
    padding: 0 28px 0 28px;
  }
}
@media screen and (max-width: 375px) {
  .borderbutton {
    box-sizing: border-box;

    width: 120px;

    height: 35px;
    margin-top: 10px;

    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 18px;
  }
  .excle-export {
    margin-top: 0px;
    width: 40px;
    display: flex;
    justify-content: center;
  }
  .excle-export p {
    display: none;
  }
  .inputsearch {
    display: flex;
    flex-direction: column-reverse;
  }
  .inputtsearch {
    display: flex;
    flex-direction: column-reverse;
  }
  .inputssearch {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 20px;
  }
  .mainWrapper {
    padding: 0 30px 0 30px;
  }
  .page-container {
    width: 0px;
  }
  .side-nav {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .borderbutton {
    box-sizing: border-box;

    width: 108px;

    height: 35px;
    margin-top: 10px;

    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 18px;
  }
  .excle-export {
    margin-top: 0px;
    width: 40px;
    display: flex;
    justify-content: center;
  }
  .excle-export p {
    display: none;
  }
  .inputsearch {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 25px;
  }
  .inputtsearch {
    display: flex;
    flex-direction: column-reverse;

  }
  .inputssearch {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 22px;
  }
  .mainWrapper {
    padding: 0 10px 0 10px;
  }
  .page-container {
    width: 0px;
  }
  .side-nav {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .inputsearches {
    display: block;
  }
  .inputtsearch {
    display: flex;
  }
}
.logoimage {
  width: 35px;
  padding-right: 10px;
}

/* Login Form */


/* login screen */
.login-banner {
  background-image: url("../image/login/loginbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.login-content {
  width: 80%;
}

.login-logo {
  width: 100%;
}

.login-title h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  mix-blend-mode: normal;
}

.login-title h3 span {
  font-weight: 500;
}

.login-form {
  width: 60%;
}

.rb-logo {
  position: absolute;
  top: 25px;
}

.form {
  height: 80vh;
}

.information {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #535353;

  opacity: 0.6;
  text-align: center;
}

.custom-form-control {
  background: #fff !important;
  padding: 15px 60px 15px 20px;
  border-radius: 50px;
}

.custom-form-control:focus {
  border-color: #FF943D;
  outline: 0;
  box-shadow: none !important
}

.passwordcustom {
  position: relative;
}

.password-see {
  position: absolute;
  right: 27px;
  top: 45%;
}

.input-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  /* Primary Text - Black #282828 */

  color: #282828;
}

.input-label sup {
  color: #FF943D;
}

.submit-btn {
  width: 100%;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 50px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;
  padding: 15px 0px;
  color: #ffff;
}

.forget-password p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  /* Primary - Orange #F27F21 */

  color: #F27F21;
}

.login-with-otp {
  background: #fff;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #FF943D;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;
  padding: 15px 0px;
  color: #FF943D;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

}

.phone-btn {
  background: rgba(122, 153, 237, 0.1);
  border-radius: 11.9444px;
  border: none;
  padding: 8px;
}

/* login screen */
/* ---------------login form media query -------------------*/

@media screen and (max-width:1338px){
  .login-form {
    width: 80%;
}
.login-title h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  mix-blend-mode: normal;
}
.information {
  font-size: 11px;
}
}
@media screen and (max-width:943px){
  .login-form {
    width: 88%;
}
.login-title h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  mix-blend-mode: normal;
}
}
@media screen and (max-width:850px){
  .form {
    height: 74vh;
}
  .login-form {
    width: 95%;
}
  .phone-btn img{
    width: 17px;
  }
  .phone-number{
    font-size: 13px;
  }
  .login-title h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #282828;
    mix-blend-mode: normal;
}
}
@media screen and (max-width:767px){
  .login-logo {
    width: 100%;
    display: none;
}
.login-banner {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 0vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login-title h3 {
  font-size: 28px;
  line-height: 44px;
}
}
@media screen and (max-width:455px){
  .login-title h3 {
    font-size: 20px;
    line-height: 26px;
  }
  .rb-logo img{
    width: 120px;
  }
  .information {
    font-size: 9px;
}
}
@media screen and (max-width:375px){
  .rb-logo img{
    width: 100px;
  }
  .phone-number {
    font-size: 11px;
}
}
@media screen and (max-width:341px){
  .login-title h3 {
    font-size: 17px;
    line-height: 26px;
}

  .phone-number {
    font-size: 10px;
}
.login-form {
  width: 100%;
}
}
/* ---------media query for header------------ */

@media screen and (max-width: 768px) {
  .logoimage{
    display: block;
  }
  .renewbuylogo {

    height: 40px;
    margin-left: 10px;
    margin-right: 100px;
    margin-top: 14px;
  }
  .renewbuylogo img {
    height: 35px;
  }
  .deicate {
    box-sizing: border-box;
    width: 173px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
  }

  .deicate p {
    width: 119px;
    height: 25px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;

    color: #535353;
  }
  .bell-img {
    width: 36px;
    height: 36px;

    background: #ffffff;
    border-radius: 10px;
  }
  .profileimg {
    width: 108px;
    height: 36px;

    background: #ffffff;
    border-radius: 10px;
  }
  .profileimg p {
    position: relative;
    right: 15px;
    width: 50px;
    height: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #535353;
  }
  .profileimg img {
    border-radius: 12px;
    position: relative;
    right: 0px;
  }
  .retesh {
    width: 36px;
    height: 36px;

    background: #ffffff;
    border-radius: 10px;
  }
}
@media screen and (max-width: 425px) {
  .renewbuylogo {
    height: 30px;
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 14px;
  }
  .renewbuylogo img {
    height: 35px;
  }
  .deicate {
    box-sizing: border-box;
    width: 28px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 14px;
  }
  .deicate img {
    width: 15px;
  }
  .deicate p {
    display: none;
  }
  .bell-img {
    width: 28px;
    height: 30px;

    background: #ffffff;
    border-radius: 14px;
  }
  .bell-img img {
    width: 15px;
  }
  .profileimg {
    width: 28px;
    height: 30px;

    background: #ffffff;
    border-radius: 10px;
  }
  .profileimg p {
    display: none;
  }
  .secondimg {
    display: none;
  }
  .profileimg img {
    border-radius: 14px;
    position: relative;
    right: 0px;
    width: 30px;
  }
  .belltext {
    position: absolute;
    width: 12px;
    top: 2px;
    left: 12px;
    height: 12px;
    background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
    border-radius: 10px;
  }
  .belltext p {
    color: #ffffff;
    position: absolute;
    width: 6px;
    height: 10px;
    left: 3px;
    top: 1px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
  }
}
@media screen and (max-width: 375px) {
  .renewbuylogo {
    height: 30px;
    margin-left: 6px;
    margin-right: 15px;
    margin-top: 14px;
  }
  .renewbuylogo img {
    height: 30px;
  }
  .deicate {
    box-sizing: border-box;
    width: 30px;
    height: 34 px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 14px;
  }
  .deicate img {
    width: 14px;
  }

  .deicate p {
    display: none;
  }
  .secondimg {
    display: none;
  }
  .bell-img {
    width: 30px;
    height: 30  px;

    background: #ffffff;
    border-radius: 14px;
  }
  .bell-img img {
    width: 14px;
  }
  .profileimg {
    width: 30px;
    height: 34px;

    background: #ffffff;
    border-radius: 10px;
  }
  .profileimg p {
    display: none;
  }
  .profileimg img {
    border-radius: 14px;
    position: relative;
    right: 0px;
    width: 34px;
  }
  .belltext {
    position: absolute;
    width: 12px;
    top: 4px;
    left: 13px;
    height: 12px;
    background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
    border-radius: 10px;
  }
  .belltext p {
    color: #ffffff;
    position: absolute;
    width: 6px;
    height: 10px;
    left: 3px;
    top: 1px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .renewbuylogo {
    height: 25px;
    margin-left: 4px;
    margin-right: 10px;
    margin-top: 14px;
    width: 29px;
  }
  .logoimage {
    width: 29px;
  }
  .renewbuylogo img {
    height: 27px;
  }
  .deicate {
    box-sizing: border-box;
    width: 26px;
    height: 28px;
    background: #ffffff;
    /* border: 1px solid #ffffff; */
    border-radius: 14px;
  }
  .deicate img {
    width: 13px;
  }
  .deicate p {
    display: none;
  }
  .secondimg {
    display: none;
  }
  .bell-img {
    width: 26px;
    height: 28px;

    background: #ffffff;
    border-radius: 14px;
  }
  .bell-img img {
    width: 13px;
  }

  .profileimg {
    width: 20px;
    height: 27px;

    background: #ffffff;
    border-radius: 14px;
  }

  .profileimg p {
    display: none;
  }

  .profileimg img {
    border-radius: 14px;
    position: relative;

    right: 0px;
    width: 26px;
  }
  .belltext {
    position: absolute;
    width: 11px;
    top: 2px;
    left: 12px;
    height: 11px;
    background: linear-gradient(180deg, #ffb171 0%, #ff943d 100%);
    border-radius: 10px;
  }
  .belltext p {
    color: #ffffff;
    position: absolute;
    width: 5px;
    height: 0px;
    left: 3px;
    top: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    /* line-height: 10px; */
    text-align: center;
  }
}
/*------------ libary/health/mediaquery-------- */

@media screen and (max-width: 1440px) {
  .title {
    height: 15px;
    display: block;
    margin-left: 5px;
    justify-content: start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.04em;
    color: #282828;
  }

  .customisebtn {
    box-sizing: border-box;
    border: 1px solid #ff943d;
    background: none;
    border-radius: 10px;
    padding: 4px 0px;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ff943d;
  }
  .descrip {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .title {
    height: 15px;
    display: block;
    margin-left: 1px;
    justify-content: start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.04em;
    color: #282828;
  }

  .customisebtn {
    box-sizing: border-box;
    border: 1px solid #ff943d;
    background: none;
    border-radius: 10px;
    width: 100%;
    padding: 4px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ff943d;
  }
  .descrip {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .title {
    height: 15px;
    display: block;
    margin-left: 0px;
    justify-content: start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.04em;
    color: #282828;
  }

  .customisebtn {
    box-sizing: border-box;
    border: 1px solid #ff943d;
    background: none;
    border-radius: 10px;
    padding: 4px 0px;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ff943d;
  }
  .descrip {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 425px) {
  .title {
    height: 15px;
    display: block;
    margin-left: 5px;
    justify-content: start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.04em;
    color: #282828;
  }

  .dropdown {
    margin-left: -10px;
  }
  .switchbutton button {
    width: 60px;
    height: 33px;
    border: none;
    color: #535353;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 4px;
  }
  .switchbtn {
    /* margin-top: 10px; */
    display: flex;
  }
  .flex-disesw {
    padding: 8px;
    padding-left: 15px;
  }
  .collateraldatas img {
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding-top: 10px;
  }
  .descrip {
    height: 15px;
    padding-bottom: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #666666;
  }

  .butonsize {
    width: 93px;
    display: flex;
    justify-content: space-between;
  }
  .butonsize img {
    padding-left: 0px;
  }
  .customisebtn {
    margin-left: -4px;
    box-sizing: border-box;
    border: 1px solid #ff943d;
    background: none;
    border-radius: 10px;
    width: 100%;
    padding: 4px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ff943d;
  }
  .dropdown {
    margin-left: -10px;
  }
  .switchbutton button {
    width: 65px;
    height: 30px;
    border: none;
    color: #535353;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 4px;
  }
}
@media screen and (max-width: 375px) {
  .title {
    height: 15px;
    display: block;
    margin-left: 5px;
    justify-content: start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.04em;
    color: #282828;
  }

  .dropdown {
    margin-left: -10px;
  }
  .switchbutton button {
    width: 60px;
    height: 33px;
    border: none;
    color: #535353;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 4px;
  }
  .switchbtn {
    /* margin-top: 10px; */
    display: flex;
  }
  .flex-disesw {
    padding: 8px;
    padding-left: 39px;
  }
  .collateraldatas img {
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding-top: 10px;
  }
  .descrip {
    height: 15px;
    padding-bottom: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #666666;
  }

  .butonsize {
    width: 40px;
  }
  .customisebtn {
    margin-left: -4px;
    box-sizing: border-box;
    border: 1px solid #ff943d;
    background: none;
    border-radius: 10px;
    width: 100%;
    padding: 4px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ff943d;
  }
  .dropdown {
    margin-left: -9px;
  }
  .switchbutton button {
    width: 65px;
    height: 30px;
    border: none;
    color: #535353;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 4px;
  }
  .collratedbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 16px;
  }
  .contante {
    padding: 7px 0px;
  }
  .collrated img {
    width: 100%;
    height: 14rem;
    display: flex;
    border-radius: 5px;
  }
}
@media screen and (max-width: 320px) {
  .title {
    margin-left: 4px;
    height: 15px;
    display: block;

    justify-content: start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #282828;
  }
  .switchbtn {
    /* margin-top: 10px; */
    display: flex;
  }
  .flex-disesw {
    padding: 8px;
    padding-left: 0px;
  }
  .collateraldatas img {
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding-top: 10px;
  }

  .descrip {
    height: 15px;
    padding-bottom: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #666666;
  }

  .butonsize {
    width: 40px;
  }
  .customisebtn {
    margin-left: -4px;
    box-sizing: border-box;
    border: 1px solid #ff943d;
    background: none;
    border-radius: 10px;
    width: 100%;
    padding: 4px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ff943d;
  }
  .dropdown {
    margin-left: -20px;
  }
  
  .switchbutton button {
    width: 70px;
    height: 30px;
    border: none;
    color: #535353;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    margin-right: 4px;
  }
}

/* ------end--------- */

@media screen and (max-width: 425px) {
  .droup {
    padding: 5px;
    padding-right: 156px;
    margin-top: -35px;
  }
  .flex-dises {
    padding-left: 158px;
  }
  .page-container {
    width: 0px;
    /* width: calc(100% - 300px); */
    height: 92vh;
    overflow-y: scroll;
    background: #fbfbfb;
}
}
@media screen and (max-width: 375px) {
  .droup {
    padding: 5px;
    padding-right: 14px;
    margin-top: -35px;
  }
  .flex-dises {
    padding-left: 176px;
  }
}
@media screen and (max-width: 325px) {
  .droup {
    padding: 5px;
    padding-right: 43px;
    margin-top: -35px;
  }
  .flex-dises {
    padding-left: 148px;
  }
  .page-container {
    width: 0px;
}
}

/*  Modal  */
#custom-modal {
  margin: 0 auto;
  width: 100%;
  border: 0;
  outline: none;
  padding: 2rem 5rem 5rem;
  max-height: 90vh !important;
  overflow-y: scroll;
  border-top: 5px solid #0040a1;
  border-radius: 30px;
  background-color: #f6f6f6 !important;
}

#custom-modal .title {
  font-size: 1.2rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
  margin-bottom: 3rem;
  text-transform: uppercase;
  border-bottom: 2px solid #0040a1;
}

#custom-modal .label,
#custom-modal .radio-label span {
  font-size: 1rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
}

#custom-modal .radio-label {
  margin-right: 2rem;
}

#custom-modal .cross {
  position: relative;
  display: block;
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
  right: -3rem;
  right: -4.8rem;
  top: -40px;
}

/* #custom-modal .cross::before {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -2px;
  right: 99px;
  position: absolute;
  transform: rotate(134deg) translate(2.5px, -0.5px);
  right: 8px;
}

#custom-modal .cross::after {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -7px;
  right: 19px;
  position: absolute;
  transform: rotate(225deg) translate(-9.5px, 0px);
  right: 16px;

} */

#custom-modal .warning-modal .title {
  color: #cb0000;
  border-bottom: 2px solid #cb0000;
  text-align: center;
}

#custom-modal .warning-modal p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

#custom-modal .warning-modal button {
  border-width: 1px !important;
  font-weight: 400 !important;
  padding: 0.3rem 2rem !important;
}

#custom-modal .warning-modal .approve_decline_padding {
  background-color: #cb0000;
  color: #fff !important;
}

#custom-modal .warning-modal .approve_decline_padding .cross-icon::before,
#custom-modal .warning-modal .approve_decline_padding .cross-icon::after {
  background-color: #fff;
}
.css-1nvyafe-JoySheet-root, .css-ewnh55{
  border: none!important;
}
.css-1tb3461{
  border: none!important;
}
.css-azw5y5-JoyModal-backdrop {
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: #282828 !important;
  opacity: 0.4;
  -webkit-tap-highlight-color: transparent;
  /* -webkit-backdrop-filter: blur(0px); */
  backdrop-filter: blur(0px) !important;
  background: #282828;
}

.css-lj6m45 {
  border: none !important;
}
.image-colrated{


  display: flex;
  justify-content: center;
  width: 70%;

}
.Brochuresimg{

  display: flex;
  justify-content: center;
  width: 65%;
}
.image-colraterl{
  display: flex;
  justify-content: center;
}

.Brochures{
  display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border-radius: 20px;
    margin-left: 40px;
}

.Brochuresbody{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 10px 40px 10px 10px;

}
.Brochuresdata img {
  width: auto;
  height: 15rem;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding-top: 0px;
}
.Brochuresdata{
display: flex;
}
 /* .Brochuresimg{
  display: flex;
  justify-content: start;
  width:70%;
 }

.Brochuresimg img{
    width: auto;
    height: 15rem;
    display: flex;
    border-radius: 5px;

} */
.Brochurecollateral-details span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  display: block;
  color: #282828;
}
.Brochurecontante {
  padding: 10px 22px;
  height: 100%;
}
.Brochurecollateral-details {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #666666;
}
.Brochuredes-size {
  font-size: 14px;
}
.buttonss-carte{
  display: flex;
  padding-top: 38px;
  width: 50%;
}
@media screen and (max-width:1440px){
  .buttonss-carte{
    display: flex;
    padding-top: 38px;
    width: 100%;
  }
}
@media screen and (max-width:1024px){
  .buttonss-carte{
    display: flex;
    padding-top: 38px;
    width: 100%;
  }
  .Brochures{
    display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      border-radius: 20px;
      margin-left: 10px;
  }
  .image-colrated{
    display: flex;
    justify-content: center;
    width: 100%;
  
  }
  .Brochuresimg{

    display: flex;
    justify-content: center;
    width: 100%;
  }
  
}
@media screen and (max-width:768px){
  .Brochures{
    display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      border-radius: 20px;
      margin-left: 0px;
  }
}
@media screen and (max-width:425px){
  .Brochuresdata{
    display: block;
    }
    .Brochuresdata img {
      width: auto;
      height: 15rem;
      display: flex;
      justify-content: center;
      border-radius: 5px;
      padding-top: 0px;
    }
    .Brochuresbody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 10px 10px 10px 10px;
  }
}

.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: orange !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: orange !important;
}

.playbutton{
  position: absolute;
  left: 44%;
}

.hl-month {
  width: 350px;
}
.hl-day {
  flex: 0 0 14.28%;
}
.hl-followus :global(.hl-day-button) {
  width: 36px;
  height: 36px;
}

/* --------------Webnar dashboard start-------------------- */
.profile{
  padding-left: 28px;
}
.abcd{
  display: flex;
  align-items: center;
  justify-content: center;
}

.samriti3{
width: 100%;
height: 27px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 27px;
letter-spacing: 0.04em;
color: #282828;
padding-bottom: 4px;
}
.samriti5{
width: 100%;
height: 20px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #747577;
padding-top: 4px;
}

.excle-exportpart {
  width: 124px;
  height: 30px;
  margin-left: 10px;
  background: none;
  border-radius: 10px;
  border: 1px solid #ff943d;
  padding: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #ff943d;
}

.excel-exportpart p{
width: 83px;
height: 12px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.04em;
background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
-webkit-text-fill-color: transparent;
}
.webinar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 30px;
margin-top: 10px;
}
.webinarbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px 20px 12px 20px ;
}
.webinardata {
width: 100%;
height: 100%;
  align-items: center;
}
.image-webinar{
  width: 100%;
}
.webinar-product-types {
  padding-right: 20px;
  padding-left: 16px;
}
.webinar-product-types span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #282828;
}
.webinar-product-types a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration: underline;
  background: linear-gradient(180deg, #5989de 0%, #4070c5 100%);
  -webkit-background-clip: text;
  background-clip: text;
}
.image-webinar{
  width: 230px;
  height: 200px;
  display: flex;
  justify-content: center;
}
.img-webinar{
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: contain;
  justify-content: center;
  border-radius: 5px;
}
.contant-webinar{
  padding-top: 10px;
  padding-left: 10px;
}
.collateral-detailwebinar{
width: 100%;
/* height: 17px; */
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.04em;
color: #282828;
}
.contein-webinar{
width: 100%;
/* height: 22px; */
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
letter-spacing: 0.04em;
color: #282828;
}
.collateral-detailss-webinar{
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #666666;
}
.registbody{
  margin-top: 10px;
}
.registerbtn {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  border: 1px solid #ff943d;
  border-radius: 300px;
  background: none;
  letter-spacing: 0.04em;
  font-size: 14px;
  color: #ff943d;
}
.webinareditbtn {
  box-sizing: border-box;
  border: 1px solid #4070C5;
  width: 32px;
  height: 32px;
  border-radius: 73px;
  background: #ffffff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webinardeletebtn {
  border: 1px solid #F74141;
  box-sizing: border-box;
  border-radius: 73px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}


/* -----------------webinar media query--------------- */

@media screen and (max-width:1024px){

  .searchingbox {
    display: flex;
    padding-right: 26px;
    padding-left: 19px;
}
.contein-webinar {
  width: 100%;
  /* height: 22px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #282828;
}
.collateral-detailss-webinar {
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #666666;
}
.collateral-detailwebinar {
  width: 100%;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.04em;
  color: #282828;
}
.registerbtn {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  border: 1px solid #ff943d;
  border-radius: 300px;
  background: none;
  letter-spacing: 0.04em;
  font-size: 16px;
  color: #ff943d;
}
.webinareditbtn {
  box-sizing: border-box;
  border: 1px solid #4070C5;
  width: 30px;
  height: 30px;
  border-radius: 73px;
  background: #ffffff;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webinardeletebtn {
  border: 1px solid #F74141;
  box-sizing: border-box;
  border-radius: 73px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.renewbuylogo {
  height: 42px;
  margin-left: 23px;
  margin-right: 80px;
}
.profile{
  padding-left: 0px;
}
.profiler {
  padding-left: 15px;
}

.samriti3{
width: 100%;
height: 27px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 27px;
letter-spacing: 0.04em;
color: #282828;
padding-bottom: 4px;
}
.samriti5{
width: 100%;
height: 20px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #747577;
padding-top: 4px;
}

}

@media screen and (max-width:768px){
  .contein-webinar{
    width: 100%;
    /* height: 22px; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #282828;
    }
    .collateral-detailss-webinar{
      width: 100%;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.04em;
      color: #666666;
    }
    .collateral-detailwebinar{
      width: 100%;
      height: 17px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.04em;
      color: #282828;
      }
      .registerbtn {
        box-sizing: border-box;
        width: 100%;
        height: 32px;
        border: 1px solid #ff943d;
        border-radius: 300px;
        background: none;
        letter-spacing: 0.04em;
        font-size: 16px;
        color: #ff943d;
      }
      .webinareditbtn {
        box-sizing: border-box;
        border: 1px solid #4070C5;
        width: 32px;
        height: 32px;
        border-radius: 73px;
        background: #ffffff;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .webinardeletebtn {
        border: 1px solid #F74141;
        box-sizing: border-box;
        border-radius: 73px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
      }
      .profile{
        padding-left: 28px;
      }
      .profiler {
        padding-left: 5px;
    }
      
      .samriti3{
      width: 100%;
      height: 27px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.04em;
      color: #282828;
      padding-bottom: 4px;
      }
      .samriti5{
      width: 100%;
      height: 20px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.04em;
      color: #747577;
      padding-top: 4px;
      }
      
}
@media screen and (max-width:640px){

  .renewbuylogo {
    height: 42px;
    margin-left: 23px;
    margin-right: 30px;
}
.profile {
  padding-left: 17px;
}
}
@media screen and (max-width:580px){

  .renewbuylogo {
    height: 42px;
    margin-left: 23px;
    margin-right: 30px;
}
.profile {
  padding-left: 17px;
}
.samriti3{
  width: 100%;
  height: 27px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 4px;
  }
  .samriti5{
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
  } 
}
@media screen and (max-width:540px){

  .renewbuylogo {
    height: 42px;
    margin-left: 23px;
    margin-right: 30px;
}
.profile {
  padding-left: 17px;
}
.samriti3{
  width: 100%;
  height: 27px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 4px;
  }
  .samriti5{
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
  } 
}
@media screen and (max-width:500px){

  .renewbuylogo {
    height: 42px;
    margin-left: 15px;
    margin-right: 25px;
}
.profile {
  padding-left: 17px;
}
.samriti3{
  width: 100%;
  height: 27px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 4px;
  }
  .samriti5{
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
  } 
}
@media screen and (max-width:450px){

  .renewbuylogo {
    height: 42px;
    margin-left: 15px;
    margin-right: 20px;
}
.profile {
  padding-left: 13px;
}
.samriti3{
  width: 100%;
  height: 27px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 4px;
  }
  .samriti5{
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
  } 
}

@media screen and  (max-width:425px){
    .searchingbox{
      flex-direction: column-reverse;
    }
    .dis-flex{
      padding-left: 222px;
      padding-bottom: 10px;
    }
    .product-service {
      border-bottom: 2px solid #f0f4f8;
      width: 94%;
      padding-left: 2px;
      padding-top: 28px;
  }
  .renewbuylogo {
    height: 42px;
    margin-left: 14px;
    margin-right: 9px;
}
  .profile{
    padding-left: 28px;
  }
  .profiler {
    padding-left: 0px;
  }
  
  .samriti3{
  width: 100%;
  height: 27px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 4px;
  }
  .samriti5{
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
  }
}
@media screen and  (max-width:420px){
  .searchingbox{
    flex-direction: column-reverse;
  }
  .dis-flex{
    padding-left: 222px;
    padding-bottom: 10px;
  }
  .product-service {
    border-bottom: 2px solid #f0f4f8;
    width: 94%;
    padding-left: 2px;
    padding-top: 28px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 12px;
  margin-right: 6px;
}
.profile{
  padding-left: 20px;
}

.samriti3 {
  width: 100%;
  height: 27px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 4px;
}
.samriti5{
width: 100%;
height: 20px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 9px;
line-height: 20px;
letter-spacing: 0.04em;
color: #747577;
padding-top: 0px;
}
}
@media screen and  (max-width:405px){
  .dis-flex{
    padding-left: 182px;
    padding-bottom: 10px;
  }
  .merchandise-libary {
    cursor: pointer;
    margin-right: 65px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 2px;
}
.profile{
  padding-left: 28px;
}

.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
}
}
@media screen and (max-width:390px){
  .merchandise-libary {
    cursor: pointer;
    margin-right: 30px;
}
}
@media screen and  (max-width:385px){
  .dis-flex{
    padding-left: 182px;
    padding-bottom: 10px;
  }
  .merchandise-libary {
    cursor: pointer;
    margin-right: 25px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 2px;
}
.profile{
  padding-left: 28px;
}
.profiler {
  padding-left: 0px;
}
.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
}
}
@media screen and (max-width:376px){
  .dis-flex{
    padding-left: 182px;
    padding-bottom: 10px;
  }
  .merchandise-libary {
    cursor: pointer;
    margin-right: 65px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 2px;
}
.profile{
  padding-left: 28px;
}

.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 0px;
}
}
@media screen and (max-width:359px){
  .searchingbox{
 margin-top: -20px;
  }
  .dis-flex {
    padding-left: 143px;
    padding-bottom: 10px;
}
  .merchandise-libary {
    cursor: pointer;
    margin-right: 50px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 6px;
}
.profile{
  padding-left: 22px;
}

.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 0px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 4px;
}
}
@media screen and (max-width:344px){
  .searchingbox{
 margin-top: -20px;
  }
  .dis-flex {
    padding-left: 143px;
    padding-bottom: 10px;
}
  .merchandise-libary {
    cursor: pointer;
    margin-right: 50px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 6px;
}
.profile{
  padding-left: 16px;
}


.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 0px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 4px;
}
}
@media screen and (max-width:338px){
  .searchingbox{
 margin-top: -20px;
  }
  .dis-flex {
    padding-left: 143px;
    padding-bottom: 10px;
}
  .merchandise-libary {
    cursor: pointer;
    margin-right: 50px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 6px;
}
.profile{
  padding-left: 12px;
}
.profiler {
  padding-left: 0px;
}



.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 0px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 4px;
}
}
@media screen and (max-width:334px){
  .searchingbox{
 margin-top: -20px;
  }
  .dis-flex {
    padding-left: 143px;
    padding-bottom: 10px;
}
  .merchandise-libary {
    cursor: pointer;
    margin-right: 50px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 6px;
}
.profile{
  padding-left: 12px;
}
.profiler {
  padding-left: 0px;
}


.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 0px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 4px;
}
}

@media screen and (max-width:320px){
  .searchingbox{
 margin-top: -20px;
  }
  .dis-flex {
    padding-left: 143px;
    padding-bottom: 10px;
}
  .merchandise-libary {
    cursor: pointer;
    margin-right: 50px;
}
.renewbuylogo {
  height: 42px;
  margin-left: 6px;
  margin-right: 100px;
}
.profile{
  padding-left: 22px;
}
.profiler{
  padding-left: 5px;
}

.samriti3 {
  width: 100%;
  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
  padding-bottom: 0px;
}
.samriti5 {
  width: 100%;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 0px;
  letter-spacing: 0.04em;
  color: #747577;
  padding-top: 4px;
}
}

/* -------Registerpage----------- */

.cardregister{

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 30px;
}
.cardregisterbody{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 20px ;
}
.cardregisterdata{
display: flex;

}
.cardregisterimage{
  display: flex;
  justify-content: center;
/* width: 20%; */
width: 20rem;
/* background: #808191; */
/* opacity: 0.1; */
height: 14rem;
padding-top: 0px;
border-radius: 15px; 
}
.registerimg{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardregisterimage img{
/* opacity: 0.1; */
  width: 100%;
  height: 14rem;
  padding-top: 0px;
  object-fit: contain;
 
  }
.cardregistercontnet{
  padding: 12px 0px 0px 40px;
  flex-wrap: wrap;
}
.registercontent{
width: 100%;
height: 24px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.04em;
color: #282828;
}
.jaysingh{
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #666666;

}
.datefixing{

font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #808191;

}
.timie{

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #282828;
}
.loremip{
padding-right: 20px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #666666;
}

.formic label{  

  width: 79px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #282828;
  
}
.formic input{

  width: 100% ;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24.2521px;
}
.formic{
  margin-left: 20px;
}
.btn-register{
border: none;
width: 21%;
height: 48px;
background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
border-radius: 70px;
margin-left: 20px;
}
.btn-register p{

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;

letter-spacing: 0.04em;
color: #FFFFFF;
transform: matrix(1, 0, 0, 1, 0, 0);
}
.register-fillingup{
width: 519px;
height: 19px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
letter-spacing: 0.04em;
background: linear-gradient(180deg, #5989DE 0%, #4070C5 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
-webkit-text-fill-color: transparent;

}
.formic-flex{
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width:1440px){
  .btn-register{
    border: none;
    width: 29%;
    height: 48px;
    background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
    border-radius: 70px;
    }
    .registercontent{
      width: 100%;
      height: 24px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.04em;
      color: #282828;
      }
      .jaysingh{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #666666;
      
      }
      .datefixing{
      
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #808191;
      
      }
      .timie{
      
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #282828;
      }
      .loremip{
      padding-right: 20px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #666666;
      }
}
@media screen and (max-width:1024px){
  .cardregistercontnet{
    padding: 12px 0px 0px 35px;
  }
.registercontent{
width: 100%;
height: 24px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 19px;
line-height: 24px;
letter-spacing: 0.04em;
color: #282828;
}
.jaysingh{
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 15px;
letter-spacing: 0.04em;
color: #666666;

}
.datefixing{

font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 15px;
letter-spacing: 0.04em;
color: #808191;

}
.timie{

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 15px;
letter-spacing: 0.04em;
color: #282828;
}
.loremip{
padding-right: 20px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 15px;
letter-spacing: 0.04em;
color: #666666;
}
.btn-register {
  border: none;
  width: 30%;
  height: 48px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 70px;
}
}
@media screen and (max-width:565px){
  .cardregistercontnet {
    padding: 12px 0px 0px 23px;
}
}
@media screen and (max-width:505px){
  .cardregistercontnet {
    padding: 12px 0px 0px 23px;
}
.cardregisterdata {
  display: block;
}
.cardregisterimage {
  display: flex;
  justify-content: center;
  width: 20rem;
  /* background: #808191; */
  /* opacity: 0.1; */
  height: 14rem;
  padding-top: 0px;
  border-radius: 15px;
}
.formic-flex {
  display: block;
  justify-content: space-between;
}
.formic {
  margin-left: 10px;
}
.btn-register {
  border: none;
  width: 100%;
  margin-left: 10px;
  height: 48px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 70px;
}
.register-fillingup {
  width: 519px;
  height: 19px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  background: linear-gradient(180deg, #5989DE 0%, #4070C5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cardregistercontnet {
  padding: 12px 0px 0px 10px;
}
}
@media screen and (max-width:425px){
  .cardregisterimage {
    display: flex;
    justify-content: center;
    width: 20rem;
    /* background: #808191; */
    /* opacity: 0.1; */
    height: 14rem;
    padding-top: 0px;
    border-radius: 15px;
}
  .cardregisterdata{
    display: block;
    
  }
  .cardregistercontnet {
    padding: 12px 0px 0px 15px;
}
.formic-flex{
  display: block;
  justify-content: space-between;
}
.formic{
  margin-left: 3px;
}
.btn-register {
  border: none;
  width: 100%;
  height: 48px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 70px;
  margin-left: 3px;
}
.register-fillingup {
  width: 482px;
  height: 19px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.04em;
  background: linear-gradient(180deg, #5989DE 0%, #4070C5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
}
@media screen and (max-width:375px){
  .register-fillingup {
    width: 482px;
    height: 19px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.04em;
    background: linear-gradient(180deg, #5989DE 0%, #4070C5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media screen and (max-width:320px){
.cardregistercontnet {
    padding: 12px 0px 0px 8px;
}
}

/* ---------------Edit Webinar---------------------- */

.editwebianr1{
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
letter-spacing: 0.04em;
color: #000000;
}
.webinartitleinput{
  width: 70%;
  padding: 0px !important;
}
.webinarinputtext{
  padding-left:20px;
}
.webinarinputtext p{

  height: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #282828;
}
.browserbox{
  box-sizing: border-box;
width: 214px;
height: 130px;
border: 1px dashed #9C9C9C;
border-radius: 19px;
padding: 8px;

}
.browserboxinner{
width: 197px;
height: 117px;
background: #F0F0F0;
border-radius: 9px;

}
.uploadfile img{
  padding-left:30px;
}

.uploadfile{
  display: block;
}

.uploadfile input{
 display: block;
  width: 116px;
  border: none;
}
.browsertext img{
  cursor: pointer;
}
.browsertext p{
  width: 116px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.pdfjpeg{
width: 102px;
height: 17px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.04em;
color: #282828;
}



.imepickerr{
  padding-left:20px;
}

.catactionpd{
  padding-left: 0px;
}

.cat{
  margin: 4px;
  border-radius: 24px;
  height:30px ;
  border: 1px solid ;
  overflow: hidden;
  float: left;
  opacity: 0.5;
  box-sizing: border-box;
  cursor: pointer;
}

.cat label span {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}
.cat label input {
  position: absolute;
  display: none;
  color: #fff !important;
  cursor: pointer;
}
.cat label input + span{color: black;width: 8.3em;height: 2.1em;}

.cat input:checked + span {
    color: #ffffff;
    text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
}

.action input:checked + span{background-color: #FF943D; }


.textfieldwidth input{
  box-sizing: border-box;
  opacity: 0.2;
  width: 100%;
height: 155px;
border: 1px solid #808191;
border-radius: 10px;
}
.datepicker {
  box-sizing: border-box;
width: 194px;
height: 32px;

border: 1px solid #808191;
border-radius: 24px;
}
.datetimepicker{
  display: flex;
  opacity: 0.7;
  
}
.css-1tcs2i1-MuiInputBase-root-MuiInput-root{
  width: 80%;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {

  border-radius: 24px !important;
  padding-right: 14px;
  height: 32px !important;
  width: 194px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 24px !important;
  height: 32px;
  width: 194px;
}
.sacecanclebtn{
  padding-left:20px;
  display: flex;
width: 45%;

}
.savebuttonedit{
width: 100%;
height: 48px;
background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
border: none;
border-radius: 70px;

color: white;
}
.canclebuttonedit{
box-sizing: border-box;
width: 100%;
height: 47px;
border: 1px solid #808191;
border-radius: 70px;
background: none;
}
@media screen and (max-width:1024px){
  .sacecanclebtn{
    width: 80%;
    
    }
}
@media screen and (max-width:768px){
  .cat label input + span {
    color: black;
    width: 8.3em;
    height: 2.1em;
}
}
@media screen and (max-width:533px){
  /* .catactionpd {
    padding-left: 0px;
} */
.cat label input + span {
  color: black;
  width: 8.3em;
  height: 2.1em;
}
}
@media screen and (max-width:468px){
  .datetimepicker {
    display: block;
    opacity: 0.7;
  
}
.imepickerr{
  padding-left:0px;
  padding-top: 15px;
}
}
@media screen and (max-width:425px){
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100%;
  }
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {

    border-radius: 24px !important;
    padding-right: 14px;
    height: 32px;
    width: 100%;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 24px !important;
    height: 32px;
    width: 100%;
  }
  .webinarinputtext{
    padding-left:0px;
  }
  .imepickerr{
    padding-left:0px;
    padding-top: 15px;
  }
  .sacecanclebtn{
    padding-left:0px;
    display: flex;
  width: 50%;
  
  }
  .catactionpd{
    padding-left: 5px;
  }
  .datetimepicker {
    display: block;
    opacity: 0.7;
    width: 100%;
}

.sacecanclebtn {
    padding-left: 0px;
    display: flex;
    width: 95%;
}
}
@media screen and (max-width:375px){
  .sacecanclebtn {
    padding-left: 0px;
    display: flex;
    width: 100%;
}
}

/* -----------Deletebutton WebinarModel ------------------*/

.webinarmodalh{

width: 317px;
height: 48px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
text-align: center;
letter-spacing: 0.04em;
color: #282828;

}
.modelbuttons{
  padding:30px 30px;
  width: 100%;
  display: flex;
  
}
.modlecanclebtn{
  width: 100%;
  height: 40px;
  border: 1px solid #808191;
  border-radius: 281px;
  color: #ABABAB;
}
.css-lj6m45 {
  position: fixed!important;
  z-index: 1300;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  --variant-borderWidth: 0px !important;
}
.css-a80qp5-JoySheet-root {
  position: fixed!important;
  z-index: 1300;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  --variant-borderWidth: 0px !important;
}
.modeldeletebtn{
width: 100%;
height: 40px;
background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
border-radius: 300px;
margin-left: 25px;
border: none;
color: white;
}
@media screen and (max-width:886px){

  .webinarmodalh{
  
  width: 317px;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  
  }
  
}
@media screen and (max-width:768px){

  .webinarmodalh{
  
  width: 317px;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  
  }
  
}
@media screen and (max-width:670px){

  .webinarmodalh{
  
  width: 317px;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center; 
  letter-spacing: 0.04em;
  color: #282828;
  
  }
  .webinarmodalh {
    width: 317px;
    height: 48px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #282828;
}
}

@media screen and (max-width:425px){

  .webinarmodalh{
  
    width: 286px;
    height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  
  }
  .modlecanclebtn{
    width: 100%;
    height: 35px;
    border: 1px solid #808191;
    border-radius: 281px;
    color: #ABABAB;
  }
  
  .modeldeletebtn{
  width: 100%;
  height: 35px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 300px;
  margin-left: 25px;
  border: none;
  color: white;
  }
  
}
@media screen and (max-width:375px){

.webinarmodalh {
  width: 236px;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
}
}
@media screen and (max-width:320px){
.webinarmodalh {
  width: 238px;
  height: 43px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
}
}
/* ---------WebinarModel2 ----------*/

.webinarmodel2img{
  padding-top: 30px;
}
.webinarmodalhllx{
  width: 317px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
text-align: center;
letter-spacing: 0.04em;
color: #282828;
padding-top: 30px;
}
@media screen and (max-width:886px){

  .webinarmodel2img{
    padding-top: 30px;
  }
  .webinarmodalhllx{
    width: 317px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  padding-top: 30px;
  }
  
}
@media screen and (max-width:768px){
  .webinarmodel2img{
    padding-top: 30px;
  }
  .webinarmodalhllx{
    width: 317px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  padding-top: 30px;
  }

}
@media screen and (max-width:682px){
  .webinarmodel2img{
    padding-top: 30px;
  }
  .webinarmodalhllx{
    width: 317px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  padding-top: 30px;
  }
}


@media screen and (max-width:450px){

.webinarmodel2img {
  padding-top: 25px;
}
.modelbuttons {
  padding: 25px 25px;
  width: 100%;
  display: flex;
}
.webinarmodalhllx {
  width: 317px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  padding-top: 25px;
}
}
@media screen and (max-width:425px){

.webinarmodalhllx {
    width: 304px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #282828;
    padding-top: 25px;
}
.main-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-top: 15px;
}
}
@media screen and (max-width:375px){

.modelbuttons {
  padding: 20px 25px;
  width: 100%;
  display: flex;
}
.modeldeletebtn {
  width: 100%;
  height: 35px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 300px;
  margin-left: 15px;
  border: none;
  color: white;
}
}
@media screen and (max-width:320px){

.modeldeletebtn {
  width: 100%;
  height: 35px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 300px;
  margin-left: 15px;
  border: none;
  color: white;
}
}

.activitymonitor{
  width: 250px;
height: 29px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
letter-spacing: 0.04em;

color: #282828;
}
.exportexcelbtn{

height: 30px;
border-radius: 10px;
border: 1px solid #FF943D;
background: none;
}
.exportexcelbtn p{
  height: 15px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
  }
  .d-pagination{
    display: flex;
    justify-content: center;
  }

  .pagination{
    padding-left: 20px;
  }

  .dflex-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flexmain-d{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 33px;
  }
  .main-first{
    display: flex;
    align-items: center;

  }
  .main-second{
    display: flex;
    align-items: center;
    justify-content: end;
    padding-left: 40px;
  }
  .searchInputtx {
    position: relative;
  }
  
  .searchInputtx{
    background: #ffffff;
    opacity: 0.2;
    border: 1px solid #808191;
    border-radius: 23px;
    padding: 8px 50px 8px 20px;

  }
  
  .searchInputtx {
    position: relative;
  }
  
  .searchInputtx input {
    width: 100%;
    border: none;
    outline: none;
  }
  
  .searchInputtx::before {
    content: url("../image/icons/search.png");
    position: absolute;
    top: 27%;
    right: 8%;
  }
  
  .searchInputtx input::placeholder {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
  
    color: #666666;
  }
@media screen and (max-width:1333px){
  .activitymonitor {
    width: 250px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.04em;
    color: #282828;
}
}
@media screen and (max-width:1103px){
  .activitymonitor {
    width: 250px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.04em;
    color: #282828;
}
}
@media screen and (max-width:1031px){
  .activitymonitor {
    width: 250px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.04em;
    color: #282828;
}
.pagination{
  padding-left: 20px;
  font-size: 16px;
}
}
@media screen and (max-width:1024px){
  .flexmain-d {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 19px;
} 
}
@media screen and (max-width:991px){
  .dflex-main{
    display: block;
    align-items: center;
    justify-content: space-between;
  }
  .activitymonitor{
    width: 250px;
  height: 29px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;
  
  color: #282828;
  }
  .main-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  .main-second{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
    padding-top: 15px;
  }

}
@media screen and (max-width:833px){
  .activitymonitor {
    width: 250px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: 0.04em;
    color: #282828;
}

}

@media screen and (max-width:698px){
  .flexmain-d {
    display: block;
    padding-left: 0px;

}
.d-pagination {
  padding-top: 20px;
}
}
@media screen and (max-width:592px){
  .activitymonitor {
    width: 250px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 29px;
    letter-spacing: 0.04em;
    color: #282828;
}
}
@media screen and (max-width:530px){
  .activitymonitor {
    width: 250px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.04em;
    color: #282828;
}
}
@media screen and (max-width:468px){
  .main-first {
    display: block;
    align-items: center;
    justify-content: space-between;
}
.main-second {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 0px;
  padding-top: 15px;
}
}


/* ------------Makerboard-------------- */

.Cheacker{
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border-radius: 30px;
}

.Cheackerbody{
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 25px 30px;
}
.Cheackerdata{
  display: flex;
}
.cheakercontent{
  width: 100%;
  padding:16px 25px 0px;
}
.cheackertitle{
  /* width: 117px;  */
/* height: 20px; */
top: 281px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.04em;
color: #282828;
}

.cheackerpass p{
  display: flex;
height: 15px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.04em;
color: #666666;
}
.cheackerpass span{
height: 15px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.04em;
color: #282828;
}
.declineapprovebtn{
  width: 100%;
  padding-top: 45px;
}
.declinebtnn{
  border: 1px solid #F64141;
  border-radius: 73px;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #F64141;
  background: transparent;

}
.approvebtnn{
border: 1px solid #00B929;
border-radius: 73px;
width: 100px;
height: 28px;
font-weight: 500;
font-size: 11px;
line-height: 15px;
color: #00B929;
background: transparent;
margin-left: 10px;
}
.approvedbtnns{
  border: 1px solid #00B929;
  border-radius: 73px;
  width: 210px  ;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #00B929;
  background: #00B929;

  color: white;
  }
  .declinedbtnns{
    border: 1px solid #F64141;
    border-radius: 73px;
    width: 210px  ;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #00B929;
    background: #F64141;
  
    color: white;
  }

.imagerelativre{
  position: relative;
}
.imageposition{
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: 31px;
  left: 5px;
}

.cheackerimg{
    width: 210px;
    height: 200px;
    display: block;
    object-fit: contain;
    justify-content: center;
}
  @media screen and (max-width:1440px){
    /* .cheackerimg{
      width: 100%;
      display: flex;
      justify-content: start;
      display: flex;
      justify-content: center;
    
    } */
    .declinebtnn{
      border: 1px solid #F64141;
      border-radius: 73px;
      width: 100%;
      height: 28px;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      color: #F64141;
      background: transparent;
    
    }
    .approvebtnn{
    border: 1px solid #00B929;
    border-radius: 73px;
    width: 100%;
    height: 28px;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #00B929;
    background: transparent;
    margin-left: 10px;
    }
  }
@media screen and (max-width:1110px){
  .Cheackerdata {
    display: block;
}

.cheackerimg{

  display: flex;
  justify-content: center;

} 
.declinebtnn{
  border: 1px solid #F64141;
  border-radius: 73px;
  width: 100%;
  height: 28px;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #F64141;
  background: transparent;

}
.approvebtnn{
border: 1px solid #00B929;
border-radius: 73px;
width: 100%;
height: 28px;
font-weight: 500;
font-size: 11px;
line-height: 15px;
color: #00B929;
background: transparent;
margin-left: 10px;
}
.approvedbtnns{
  border: 1px solid #00B929;
  border-radius: 73px;
  width:100% ;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #00B929;
  background: #00B929;

  color: white;
  }
  .declinedbtnns{
    border: 1px solid #F64141;
    border-radius: 73px;
    width: 100%  ;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #00B929;
    background: #F64141;
  
    color: white;
  }
}
@media  screen and (max-width:768px) {
  .main-second {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    padding-top: 15px;
}
}
@media screen and (max-width:600px){
  .cheakercontent {
    width: 100%;
    padding: 16px 0px 0px;
}
}
@media screen and (max-width:425px){
  .product-servicex {
    border-bottom: 2px solid #f0f4f8;
    width: 100%;
  padding-left: 0px; 
    padding-top: 0px;
  }
  .merchandise-libarye {
    cursor: pointer;
    margin-right: 41px;
}
.gobacksize{
  font-size: 16px;
}
.main-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-top: 15px;
}
}
@media screen and (max-width:390px){
  .merchandise-libarye {
    cursor: pointer;
    margin-right: 25px;
}
}
@media screen and (max-width:375px){
  .merchandise-libary h4 {
    width: 145px;
    height: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #535353;
}
.collaterals-lab h4 {
  width: 95px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
} 
.merchandise-libarye h4 {
  width: 145px;
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #535353;
}
.collaterals-labe h4 {
width: 130px;
height: 17px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
letter-spacing: 0.04em;
color: #747577;
} 
.merchandise-libarye {
  cursor: pointer;
  margin-right: 38px;
}
.product-servicex {
  border-bottom: 2px solid #f0f4f8;
  width: 94%;
padding-left: 0px; 
  padding-top: 18px;
}
}
@media screen and (max-width:320px){
  .merchandise-libary h4 {
    width: 157px;
    height: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #535353;
}
.collaterals-lab h4 {
  width: 109px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #747577;
}
.merchandise-libarye h4 {
  width: 145px;
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #535353;
}
.collaterals-labe h4 {
width: 118px;
height: 17px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 17px;
letter-spacing: 0.04em;
color: #747577;
}
.merchandise-libarye {
  cursor: pointer;
  margin-right: 17px;
}
.merchandise-libary {
  cursor: pointer;
  margin-right: 35px;
}
.product-servicex {
  border-bottom: 2px solid #f0f4f8;
  width: 94%;
padding-left: 0px; 
  padding-top: 18px;
}
}
.btn{
  padding: 0px;
}
.webinarmodelboxces{
  box-shadow: 24;
  width: 566px;
  height: 306px;
  max-width: 653px;
  background: #FFFFFF;
  border-radius: 30px;
  border: none;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.webinarmodalhes{

  width: 413px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
  
  }
  .webinarinputtextes{
    padding-left:30px;
    padding-right:30px ;
  }
  .webinarinputtextes p{
  
    height: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #666666;
    padding-top: 10px;
  }
  .textfieldwidthes{
    padding-top: 20px;
  }
  .textfieldwidthes input{

    opacity: 0.3;
    width: 100%;
  height: 109px;
  border: 1px solid #808191;
  border-radius: 8px;
  }
  .peoplelabin{
    display: flex;
  }

  .peoplelabin p{
    /* width: 240px; */
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #282828;
    margin-bottom: 5px;
    padding-left: 10px;
  }

  .peoplelabin input{
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #808191;
    border-radius: 24px;

  }
  .peoplelabin input::placeholder{
    height: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    padding: 16px 30px;
    color: #808191;
  }

  .peoplelabines{

    align-items: center;
  }
  .peoplelabines p{
    width: 131px;
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #282828;
    margin-bottom: 5px;
    padding-left: 10px;
  }
  .peoplelabines input{
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #808191;
    border-radius: 24px;
  }
  .peoplelabines input::placeholder{
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;

padding: 5px 16px;
    color: #808191;
  }
  
  .modelbuttonses{
    padding:30px 0px;
    width: 100%;
    display: flex;
    
  }
.viewdeletebtn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.scolltable{
  overflow-y: scroll;
}
.peopletableaddbtn{
  padding-left: 40px;
}

  @media screen and (max-width:896px){
  .peoplelabin input {
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #808191;
    border-radius: 24px;
}
.peoplelabines input {
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.cardregisterimage {
  width: 17rem;
  height: 14rem;
}
  }
  @media screen and (max-width:832px){

.peoplelabin input {
  height: 46px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabines input {
height: 28px;
background: #FFFFFF;
border: 1px solid #808191;
border-radius: 24px;
}
.peoplelabin input::placeholder{
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  padding: 12px 20px;
  color: #808191;
}
.peoplelabines input::placeholder{
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

padding: 5px 12px;
  color: #808191;
}
  }
  @media screen and (max-width:768px){
    .peoplelabin input{
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #808191;
      border-radius: 24px;
  
    }
    .peoplelabin input::placeholder{
      height: 14px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.04em;
      padding: 16px 30px;
      color: #808191;
    }
    .peoplelabines input{
      height: 28px;
      background: #FFFFFF;
      border: 1px solid #808191;
      border-radius: 24px;
    }
    .peoplelabines input::placeholder{
      height: 17px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.04em;
  
  padding: 5px 16px;
      color: #808191;
    }
    .d-pagination{

    align-items: center;
    justify-content: space-between;
    }
    .pagination {
      padding-left: 0px;
      font-size: 16px;
  }
  }
  @media screen and (max-width:686px){

  .webinarmodalhes {
    width: 413px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #282828;
} 
.webinarinputtextes p {
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #666666;
  padding-top: 10px;
}

.peoplelabin input {
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabin input::placeholder{
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  padding: 14px 20px;
  color: #808191;
}
.peoplelabines input{
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabines input::placeholder{
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

padding: 5px 12px;
  color: #808191;
}
.cardregisterdata {
  display: block;
}

  }
  @media screen and (max-width:600px){

  .webinarinputtextes {
    padding-left: 25px;
    padding-right: 25px;
}

.peoplelabin input {
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabin input::placeholder{
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  padding: 14px 14px;
  color: #808191;
}
.peoplelabines input{
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabines input::placeholder{
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

padding: 5px 12px;
  color: #808191;
}
  }

  @media screen and (max-width:534px){

  .webinarinputtextes {
    padding-left: 12px;
    padding-right: 12px;
}
  .webinarinputtextes p {
    height: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #666666;
    padding-top: 1px;
}
.webinarmodalhes {
  width: 413px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
}
.textfieldwidthes {
  padding-top: 6px;
}
.textfieldwidthes input {
  opacity: 0.3;
  width: 100%;
  height: 100px;
  border: 1px solid #808191;
  border-radius: 8px;
}
.modelbuttons {
  padding: 25px 30px;
  width: 100%;
  display: flex;
}
.modlecanclebtn {
  width: 100%;
  height: 35px;
  border: 1px solid #808191;
  border-radius: 281px;
  color: #ABABAB;
}
.modeldeletebtn {
  width: 100%;
  height: 35px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 300px;
  margin-left: 25px;
  border: none;
  color: white;
}

.peoplelabin input {
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabin input::placeholder{
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  padding: 12px 12px;
  color: #808191;
}
.peoplelabines input{
  height: 25px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24px;
}
.peoplelabines input::placeholder{
  height: 17px;
  font-family: 'Montserrat';  
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

padding: 5px 8px;
  color: #808191;
}
  }
  @media screen and (max-width:500px){

  .peoplelabin input {
    background: #FFFFFF;
    border: 1px solid #808191;
    border-radius: 24px;
  }
  .peoplelabin input::placeholder{
    height: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    padding: 12px 12px;
    color: #808191;
  }
  .peoplelabines input{
    height: 25px;
    background: #FFFFFF;
    border: 1px solid #808191;
    border-radius: 24px;
  }
  .peoplelabines input::placeholder{
    height: 17px;
    font-family: 'Montserrat';  
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
  
  padding: 5px 8px;
    color: #808191;
  }
  }
  @media screen and (max-width:460px){

  .webinarmodalhes {
    width: 387px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #282828;
}
.webinarinputtextes p {
  height: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 6px;
  letter-spacing: 0.04em;
  color: #666666;
  padding-top: 0px;
}
.modlecanclebtn {
  width: 100%;
  height: 33px;
  border: 1px solid #808191;
  border-radius: 281px;
  color: #ABABAB;
  font-size: 14px;
}
.modeldeletebtn {
  width: 100%;
  height: 33px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 300px;
  margin-left: 25px;
  border: none;
  color: white;
  font-size: 14px;
}

.peoplelabin input {
height: 44px;
background: #FFFFFF;
border: 1px solid #808191;
border-radius: 24px;
}
.peoplelabin input::placeholder{
height: 14px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.04em;
padding: 12px 12px;
color: #808191;
}
.peoplelabines input{
height: 25px;
background: #FFFFFF;
border: 1px solid #808191;
border-radius: 24px;
}
.peoplelabines input::placeholder{
height: 17px;
font-family: 'Montserrat';  
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.04em;

padding: 5px 8px;
color: #808191;
}
  }
@media screen and (max-width:385px){

.webinarmodalhes {
  width: 387px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
}
.peopletableaddbtn{
  padding-left: 0px;
}

}
@media  screen and (max-width:400px) {
  .webinarmodelboxces {
    width: 100%!important;
  }}
@media  screen and (max-width:340px) {

.webinarinputtextes p {
  height: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #666666;
  padding-top: 3px;
}
.webinarmodalhes {
  width: 193px;
  height: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #282828;
}

}
@media screen and (max-width:320px){


.profile_avtar_details h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #282828;
}
.profile_avtar_details p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #808191;
  margin-top: 7px;
}
}

.contained-mi{
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 20px !important;  
  width: 77px;
height: 32px;
background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
border-radius: 10px;
  color: #FFFFFF !important;
  /* position: relative;
  text-transform: lowercase  !important; */
}
.celendarbody .css-1w1rijm-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
}
.celendarbody .css-5bcq5j{
  text-transform: none !important;
}
.celendarbody  .css-i4bv87-MuiSvgIcon-root{
  opacity: 0.4;
}
.celendarbody .css-vubbuv{
  opacity: 0.4;
}
.celendarbody{
  /* height: 300px; */
  /* overflow: scroll; */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: antiquewhite;
  width: 309px!important;
  position: absolute;
  width: 309px;
  height: 40px !important;
  right: 0%;
  top: 121%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 3px 3px 3px 3px !important;
  border: 0.8px solid #E4E5E7;
  box-shadow: 1px 1px 2px #E4E5E7;
  z-index: 999;
}
.celendarbody .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  top: -10px!important;
}
.celendarbody .css-p0rm37{
  top: -10px !important;
}

.celendarbody .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  font-size: 12px !important;
  padding-right: 13px !important;
  width: 110px !important;
}
.css-1bn53lx{
  border-radius: 24px !important;
  height: 32px !important;
  width: 194px;
}
.css-1v4ccyo{
  border-radius: 24px !important;
  height: 32px !important;
  width: 194px;
}
.celendarbody .css-1bn53lx{
  font-size: 12px !important;
  padding-right: 13px !important;
  width: 110px !important;
  border-radius: 24px !important;
}
.celendarbody .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{

  padding: 4.5px 2px !important;

}
.celendarbody .css-1uvydh2{
  padding: 4.5px 2px !important;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
  padding: 6px !important;
}
.css-slyssw {
  padding: 6px !important;
}
.celendarbody label{
  opacity: 0.4;
}
.celendarbody .css-1bn53lx{
border-radius: 24px !important;
padding-right: 14px;
height: 32px !important;
width: 194px;
}
.fromcalendar{
  box-sizing: border-box;
  margin-right: 50px;
  width: 110px !important;
  height: 30px;
  background: #0040a1;
  border-radius: 10px;
}
.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #E4E5E7;
}


.button-Calender{
  position: relative;
}
.textarea {
width: 100%;
opacity: 0.5;
border: 1px solid #808191;
border-radius: 10px;
}
@media screen and (max-width:567px){
  .textarea {
    width: 100%;
    opacity: 0.5;
    border: 1px solid #808191;
    border-radius: 10px;
    height: 5rem;  
  }
}
@media screen and (max-width:425px){
  .celendarbody {
    top: 82%;
  }
}
@media screen and (max-width:359px){
  .celendarbody {
    top: 82%;
    left: -7%;
  }
}
@media screen and (max-width:325px){
  .celendarbody {
    top: 82%;
    left: -4%;
  }
}
.table-over{
  overflow-y: auto;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  
}
.pagination .pages {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pagination .page {
  height: 40px;
  width: 38px;
  border-radius: 50%;
  cursor: pointer;
  color: #808191;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
/* .pagination .page:hover {
  background-color: #087f5b;
  color: #fff;
} */
.pagination p{
  color: #808191;
}

/* .pagination .active {
  background-color: #087f5b;
  color: #fff;
} */

/* .pagination .btn {
  background-color: #fff;
  border: 1px solid #087f5b;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .pagination .btn:hover {
  background-color: #087f5b;
} */
.pagination .btn--icon {
  height: 24px;
  width: 24px;
  color: #808191;
}
/* .pagination .btn--icon:hover {
  stroke: #fff;
} */
.inputDrops {
  border: 1.14828px dashed #9C9C9C;
  border-radius: 20.669px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f2f1;
  cursor: pointer;
}
.collateral-description textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #808191;
  border-radius: 10px;
}
.decline-details {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #F74141;
}

.approve-details {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #00B929;
}
.abcd img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover{
  border: none !important;
}